/** @define InputFile; use strict */

.InputFile {
  @include controlSizeDefault;
  border: solid 2px $colors-border;
  border-radius: $border-radius;
  background-color: #fff;
  color: $colors-gray-medium;
  display: inline-block;
  cursor: pointer;
}

.InputFile.is-invalid {
  border-color: $colors-danger;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.InputFile-control {
  /* the following rules hide the file input,
    but don't pull it put of tab order or stop
    the values from being sent to the server */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: $z-index-hidden;
}
