#__next {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;

  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }

  &.locked {
    overflow: hidden;
  }
  @media #{$media-sm-max} {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}

body.offsetInput {
  #FooterBar {
    display: none;
  }
}

.PageWrapper {
  max-width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5em;

  .NavTop-push {
    margin-top: 0px !important;
    @media #{$media-md-max} {
      padding-top: 1em;
      padding-bottom: 1em;
      margin-top: 0px;
    }
  }

  @media #{$media-md-max} {
    display: inherit !important;
    justify-content: start;
  }

  @media #{$media-md-max} {
    .PageWrapper--md-middle {
      display: flex !important;
      justify-content: center;
    }
  }

  &.isHome {
    background-position: top center;
    overflow-y: auto;
    padding-bottom: 0em;
  }

  @media #{$media-md-max} {
    &.isHome {
      background-position: 0px 54px;
      background-size: 160%;

      .PageWrapper-content {
        padding-bottom: 0em !important;
      }
      .NavTop-push {
        padding-bottom: 0em !important;
        margin-top: 54px !important;
      }

      .Container {
        padding: 0;
      }
    }
  }

  .NavTop-push {
    @media #{$media-sm-max} {
      padding-top: 0em !important;
      padding-bottom: 0em;
    }
  }

  @media #{$media-sm-max} {
    &.isHome {
      background-position: -140px 54px;
      background-size: 280%;

      .PageWrapper-content {
        padding-bottom: 0em !important;
      }
      .NavTop-push {
        padding-top: 2em !important;
        padding-bottom: 0em !important;
      }
    }
    &.isHome {
      &.isMobile {
        background-position: 0px 0px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
        overflow: auto;
        &.loggedIn {
          background-position: 0px 54px;
          background-size: 100%;
        }
      }
      &.loggedIn {
        background-position: -61px 54px;
        background-size: 240%;
      }
      &.isMobile {
        &.loggedIn {
          background-position: center;
          background-size: cover;
          background-color: #fff;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

@media #{$media-sm-max} {
  .isIOS {
    .isHome {
      &.isMobile {
        background-position: 0px -40px;
      }
    }
  }
}

@media #{$media-sm-max} {
  .PageWrapper--backgroundTop {
    display: inherit;
    background-size: 100% 70%;
    background-position: 0 0;
    &.page-blw-details {
      background-position: 0 -45px;
      background-size: 100% 70%;
    }
    .Panel--shadow {
      box-shadow: none;
    }
  }
}

.vh100 {
  min-height: 100vh !important;
}

.PageWrapper--noflex {
  display: inherit;
}

@media #{$ipad-pro-portrait-breakpoint} {
  .PageWrapper--ip-flex {
    display: flex;
  }
}

.PageWrapper-container {
  min-height: 100%;
  @media #{$media-sm-max} {
    margin-top: 4em;
    margin-bottom: 0em;
  }
}

.PageWrapper-content {
  @media #{$media-md-max} {
    padding-top: 5.4em !important;
    padding-bottom: 4em !important;
  }

  @media #{$media-sm-max} {
    padding-top: 5.4em !important;
    padding-bottom: 0em !important;
  }
}

.PageWrapper-title {
  @media #{$media-md-max} {
    padding: 2.375em 1.6em !important;
  }
}
@media #{$media-md-max} {
  .PageWrapper-title--big {
    padding-top: 5.5em !important;
    padding-bottom: 5.7em !important;
  }
}

.isHome {
  .PageWrapper-content {
    @media #{$media-md-max} {
      padding-top: 0em !important;
    }

    @media #{$media-sm-max} {
      padding-top: 0em !important;
    }
  }
}

.pageContainer {
  @media #{$media-md-min} {
    max-width: 1400px;
  }
}

.PageWrapper-content {
  @media #{$media-sm-max} {
    .Container {
      padding: 0em !important;
    }
  }
}
