.Table {
  empty-cells: show;
  border: 1px solid $colors-border;
  border-radius: $border-radius;
  width: 100%;
}

.Table,
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.Table thead {
  background-color: $colors-bg-body;
  color: #000;
  text-align: left;
  vertical-align: bottom;
  border-bottom: 1px solid $colors-border;
}

.Table th,
.Table td {
  padding: 0.5em 1em;
}

.Table td:first-child,
.Table th:first-child {
  border-left-width: 0;
}

.Table td,
.Table th {
  border-left: 1px solid $colors-border;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  padding: 0.5em 1em;
}

.Table--stripped tr:nth-child(even) td {
  background-color: $colors-gray-extra-light;
}

.ClaimsTable th {
  text-align: left;
  font-weight: 300 !important;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  .ClaimsTable {
    display: table;
    width: 100%;

    /* Force table to not be like tables anymore */
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    th {
      text-align: left;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 50%;
      text-align: right;
      margin-bottom: 8px;
    }

    tr {
      border-bottom: 1px solid rgba(189, 227, 253, 0.768684);
      margin-bottom: 18px;
      padding-bottom: 18px;
    }

    tr:last-child {
      border-bottom: 0px solid rgba(189, 227, 253, 0.768684);
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 0px;
      left: 0px;
      width: 50%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: 300;
    }

    /*
	Label the data
	*/
    td:nth-of-type(1):before {
      content: 'Claim type';
    }
    td:nth-of-type(2):before {
      content: 'Was the damaged repaired?';
    }
    td:nth-of-type(3):before {
      content: 'Claimed on';
    }
  }
}
