.top-location-header {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: relative;
}

.scroll-over-fade {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.left-fade {
  height: 100%;
  width: 80px;
  opacity: 0;
  background-image: linear-gradient(to right, white , transparent);

  @media only screen and (max-width: 64px) {
    width: 64px;
  }
}

.right-fade {
  height: 100%;
  width: 80px;
  background-image: linear-gradient(to left, white , transparent);

  @media only screen and (max-width: 64px) {
    width: 64px;
  }
}

.top-location-header::-webkit-scrollbar {
  display: none;
}