/* V4 Colors */
$water: #0751c2;
$water-hover: #0a4195;
$water-active: #083170;
$deep-sea: #102a5e;
$deep-sea-dark: #495d85;
$seafoam: #72d4ba;
$moss: #2a8500;
$sea-weed: #0e7873;
$seaweed-dark: #1a615d;
$seaweed-darker: #154353;
$sun: #f4c064;
$sun-dark: #e7a433;
$sunset: #bc350a;
$sunset-violet: #84365d;
$clear-sky: #bde3fd;
$midnight: #17233c;
$rock: #5e696a;
$mid-rock: #8e9697;
$light-rock: #c4c4c4;
$stone: #dbdfe5;
$light-stone: #f9fafe;
$oceanic: #00677d;
$oceanic-dark: #004e60;
$oceanic-darker: #154353;
$dark-gray: #4b5765;
$captain: #154353;
$colors-captains: #00acb7;

/* notifications */
$colors-notice: $seafoam;
$colors-error: $sunset;
$colors-warning: $sun;
/* boatsetter primary */
$colors-primary: $water;
$colors-primary-light: #fff;
$colors-primary-dark: $water-active;
$colors-primary-darker: $deep-sea;
$colors-primary-hover: $water-hover;
$colors-blue-light: $clear-sky;
$colors-blue-lighter: lighten($clear-sky, 12.5%);

/* boatsetter secondary */
$colors-secondary: $seafoam;
$colors-secondary-light: $seafoam;
$colors-secondary-dark: $seafoam;
$colors-secondary-hover: $seafoam;

/* boatsetter owners */
$colors-owners: $sea-weed;
$colors-owners-light: $sea-weed;
$colors-owners-dark: $sea-weed;
$colors-owners-hover: $sea-weed;

/* boatsetter colors */
$bs-gray: $rock;
$bs-black: $midnight;
$bs-darkBlue: $deep-sea;
$bs-darkGreen: $sea-weed;
$bs-aquamarine: $oceanic;
$bs-newblue: #102a5e;
$bs-darkAquamarine: $oceanic-dark;

/* search bar input */
$bs-darkBlue-input: $deep-sea-dark;
$bs-darkGreen-input: $seaweed-dark;
$bs-aquamarine-input: $oceanic-dark;
$bs-darkAquamarine-input: $oceanic-darker;

/* admin colors */
$admin-header-primary-color: rgb(64, 73, 105);
$admin-header-primary-color-dark: #353c56;
$admin-header-secondary-color: rgb(220, 85, 44);
$admin-header-body-bg: rgb(189, 228, 244);

/* grays */
$colors-gray-extra-light: $light-stone;
$colors-gray-extra-lighter: $light-rock;
$colors-gray-lighter: $stone;
$colors-gray-light: $mid-rock;
$colors-gray-medium: $rock;
$colors-gray-dark: $midnight;

/* forms */
$colors-search: #b4b2a3;
$colors-input-placeholder: #b4b4b4;
$colors-label: #787878;

/* danger */
$colors-danger: $sunset;
$colors-danger-dark: $sunset;
$colors-danger-light: $sunset;

/* info */
$colors-info: $sun;

$colors-bg-body: $light-stone;
$colors-border: $stone;
$colors-border-faint: $light-stone;
$colors-border-light: $stone;
$colors-border-dark: $mid-rock;

/* scrolling list */
$colors-scrolling-list-hover-text: #444;
$colors-scrolling-list-hover-bg: #dde4ef;

/* star rating */
$colors-star-rating: $seafoam;

/* notifications */
$colors-notification-bubble: $seafoam;

/* heart */
$colors-heart: $seafoam;

/* facebook */
$colors-facebook: #385185;

/* google */
$colors-google: #dd4b39;

/* twitter */
$colors-twitter: #55acee;

/* whatsapp */
$colors-whatsapp: #25d366;

/* messenger */
$colors-messenger: #0084ff;

/* tripadvisor */
$colors-tripadvisor: #169e42;

/* success */
$colors-success: $moss;
$colors-success-dark: $moss;
$colors-success-light: $moss;

/* positive */
$colors-positive: #00aecc;

/* money */
$colors-money: #5cc16e;

/* pending */
$colors-pending: $sun;

/* callout box */
$colors-callout-box: #b7dbec;

//Renter
$renter: $water;
$renter-hover: $water-hover;
$renter-active: $water-active;

//Captain
$captain: $oceanic;
$captain-hover: $oceanic-dark;
$captain-active: $oceanic-darker;

//Owner
$owner: $sea-weed;
$owner-hover: $seaweed-dark;
$owner-active: $seaweed-darker;
