/** @define Button; use strict */

.Button {
  @include controlSizeDefault;
  -webkit-appearance: none;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  white-space: normal;
  font-weight: normal;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  line-height: normal;
  border-radius: $border-radius-button;
  color: $colors-gray-medium;
  transition: all 0.3s ease;
  text-transform: uppercase;
  height: 55px;
  font-weight: bold;
  font-size: 0.875em;
  align-items: center;
}

.Button--noHeight {
  height: auto;
}

.Button--pointer {
  cursor: pointer;
}
.Button--disabled {
  cursor: default;
  pointer-events: none;
}

/* sizing */
.Button--short {
  @include controlSizeShort;
}

.Button--shorter {
  @include controlSizeShort;
  height: 34px;
}

.Button--wide {
  @include controlSizeWide;
}

.Button--superWide {
  padding-left: 3em;
  padding-right: 3em;
}

.Button::-moz-focus-inner {
  border: 0;
}

.Button--primary {
  border: solid 1px $colors-primary;
  border-radius: $border-radius-button;
  background-color: $colors-primary;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
  &:focus,
  &:hover {
    background-color: $water-hover;
    color: #fff;
  }
  &:active {
    background-color: $water-active;
  }
}

.bgSeaWeed {
  background-color: #0e7873 !important;
}

.Button {
  &:hover {
    &.noHover {
      background-color: #0751c20f !important;
    }
  }
}

.Button--primaryDark {
  border: solid 1px #0a4195;
  border-radius: $border-radius-button;
  background-color: #0a4195;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
  &:focus,
  &:hover {
    background-color: $water-active;
    color: #fff;
  }
  &:active {
    background-color: $water-active;
  }
}

.Button--primary:disabled {
  background-color: $colors-gray-lighter;
  border-color: $colors-gray-lighter;
  color: #fff;
}

input[type='checkbox']:checked + .Button--label,
input[type='radio']:checked + .Button--label {
  border: solid 1px $colors-primary;
  border-radius: $border-radius-button;
  background-color: #fff;
  transition: all 0.3s ease;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-medium;
  }
  &:focus,
  &:hover {
    border: solid 1px $colors-primary;
  }
}

.Button--facebook {
  background-color: $colors-facebook;
  border: solid 1px transparent;
  border-radius: $border-radius-button;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}
.Button--facebook:disabled {
  background-color: lighten($colors-facebook, 5%);
  opacity: 0.7;
}

.Button--google {
  background-color: $colors-google;
  border: solid 1px transparent;
  border-radius: $border-radius-button;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}
.Button--google:disabled {
  background-color: lighten($colors-google, 5%);
  opacity: 0.7;
}

.Button--tripadvisor {
  background-color: $colors-tripadvisor;
  border: solid 1px transparent;
  border-radius: $border-radius-button;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}

.Button--primaryOutline {
  border: solid 1px $colors-primary;
  border-radius: $border-radius-button;
  color: $colors-primary;
  background-color: transparent;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-primary;
  }

  &:hover {
    background-color: $colors-primary;
    color: #fff;
  }
}

.Button--secondary {
  border: solid 1px $colors-primary;
  border-radius: $border-radius-button;
  background-color: #fff;
  transition: all 0.3s ease;
  color: $colors-primary;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-primary;
  }
  &:focus,
  &:hover {
    background-color: $colors-primary;
    color: #fff;
    transition: all 0.3s ease;
  }
}
.Button--secondary:disabled {
  background-color: #fff;
  border-color: $colors-gray-lighter;
  color: $colors-gray-lighter;
  opacity: 1;
}

.Button--secondaryFlat {
  border: solid 1px $colors-primary;
  border-radius: $border-radius-button;
  background-color: #fff;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-primary;
  }
}

.Button--secondaryFlat:disabled {
  background-color: #fff;
  border-color: $colors-gray-lighter;
  color: $colors-gray-lighter;
  opacity: 1;
}

.Button--secondaryOutline {
  border: solid 1px $colors-primary;
  border-radius: $border-radius-button;
  color: $colors-primary;
  background-color: transparent;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-primary;
  }

  &:hover {
    background-color: transparent;
    color: $colors-primary;
  }
}

.Button--secondaryOutline:disabled {
  border-color: $colors-gray-lighter;
  opacity: 0.7;
  &:hover {
    background-color: transparent;
    color: $colors-gray-lighter;
  }
}

.Button--owners {
  border: solid 1px $colors-owners;
  border-radius: $border-radius-button;
  background-color: $colors-owners;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
  &:focus,
  &:hover {
    background-color: $colors-owners;
    color: #fff;
  }
}

.Button--blwIntro {
  min-width: 130px;
  width: 140px !important;
  float: right;
  height: inherit !important;
  padding: 1.25em 1.2em;
}

.Button--blw {
  width: 140px !important;
  height: inherit !important;
  padding: 1.25em 1.2em !important;
  @media #{$media-md-viewport} {
    width: 140px !important;
    padding: 0.9em 1.5em;
  }
  @media #{$media-sm-viewport} {
    padding: 0.9em 1.5em;
  }
}

.Button--blw--short {
  width: 140px !important;
}

.Arrange-sizeFit {
  .Button--blwStart {
    width: 170px !important;
    padding: 0.9em !important;
  }
}

.Button--owners:disabled {
  background-color: $colors-gray-lighter;
  border-color: $colors-gray-lighter;
  color: $colors-gray-light;
  &,
  &:link,
  &:visited,
  &:focus,
  &:active {
    background-color: $colors-gray-lighter;
    border-color: $colors-gray-lighter;
    color: $colors-gray-light;
  }

  &:hover {
    background-color: $colors-gray-lighter;
    border-color: $colors-gray-lighter;
    color: $colors-gray-light;
  }
}

.Button--whiteOutline {
  border: solid 1px #fff;
  border-radius: $border-radius-button;
  color: #fff;
  background-color: transparent;
  &,
  &:link,
  &:visited,
  &:focus,
  &:active {
    color: #fff !important;
  }

  &:hover {
    background-color: #fff;
    color: $colors-gray-dark !important;
  }
}

.Button--yellow {
  border: solid 1px $sun;
  border-radius: $border-radius;
  background-color: $sun;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #001d58;
  }
  &:focus,
  &:hover {
    background-color: $owner-hover;
    color: #001d58;
  }
  &:active {
    background-color: $owner-active;
  }

  &.is-disabled {
    background-color: $colors-gray-lighter;
    border-color: $colors-gray-lighter;
    color: $colors-gray-light;
    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active,
    &:focus,
    &:hover {
      background-color: $colors-gray-lighter;
      border-color: $colors-gray-lighter;
      color: $colors-gray-light;
    }
  }
}

.Button--yellow:disabled {
  background-color: $colors-gray-lighter;
  border-color: $colors-gray-lighter;
  color: $colors-gray-light;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active,
  &:focus,
  &:hover {
    background-color: $colors-gray-lighter;
    border-color: $colors-gray-lighter;
    color: $colors-gray-light;
  }
}

.Button--ownersOutline {
  border: solid 1px $sea-weed;
  border-radius: 6px;
  color: $sea-weed;
  background-color: transparent;
  &,
  &:link,
  &:visited,
  &:focus,
  &:active {
    color: $sea-weed !important;
  }

  &:hover {
    background-color: $sea-weed;
    color: white !important;
  }
}

.Button--neutral {
  border: solid 1px $colors-gray-lighter;
  border-radius: $border-radius-button;
  background-color: #fff;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-medium;
  }
}

.Button--neutralWhite {
  border: solid 1px $colors-border;
  border-radius: $border-radius-button;
  background-color: #ffffff;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-dark;
  }
}

.Button--white {
  border: solid 1px $colors-border;
  border-radius: $border-radius-button;
  background-color: #fff;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-medium;
  }
}

.Button--danger {
  border: solid 1px $colors-danger-dark;
  border-radius: $border-radius-button;
  background-color: $colors-danger;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
  &:focus,
  &:hover {
    background-color: $colors-danger-light;
  }
}

.Button--success {
  border: solid 1px $colors-success-dark;
  border-radius: $border-radius-button;
  background-color: $colors-success;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
  &:focus,
  &:hover {
    background-color: $colors-success-light;
  }
}

.Button--twitter {
  border: solid 1px $colors-twitter;
  border-radius: $border-radius-button;
  background-color: $colors-twitter;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}

.Button--whatsapp {
  border: solid 1px $colors-whatsapp;
  border-radius: $border-radius-button;
  background-color: $colors-whatsapp;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}

.Button--messenger {
  border: solid 1px $colors-messenger;
  border-radius: $border-radius-button;
  background-color: $colors-messenger;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}

.Button--shadow {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
}

.Button--large {
  padding: 1.2em 2em;
  @media #{$media-sm-viewport} {
    padding: 1.063em 1.5em;
  }

  @media #{$media-sm-max} {
    padding: 1.063em 1em;
  }
  @media only screen and (max-width: 66em) {
    &.Button-xs--large {
      padding: 1.563em 0.6em;
      font-size: 0.7em;
    }
  }
}

.Button--sm-larger {
  @media #{$media-sm-viewport} {
    padding: 1.3em 2em;
  }
}

.Button--rounded {
  border-radius: 25px;
  padding: 0.6em 2em;
}

.Button--thin {
  border-width: 1px;
}

.Button--thick {
  border-width: 2px;
}

.Button--tiny {
  padding: 0.25em 0.75em;
}


.Button {
  &.is-loading {
    position: relative;
    background-color: $colors-gray-lighter;
    border-color: $colors-gray-lighter;
    color: #fff;

    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active,
    &:focus,
    &:hover {
      background-color: $colors-gray-lighter;
      border-color: $colors-gray-lighter;
      color: $colors-gray-light;
    }
  }
}

.Button--100w {
  width: 100px;
}

#MessageOwnerWidget {
  #submitFormButton {
    height: 65px;
    @media #{$media-sm-max} {
      height: 55px;
    }
  }
}

.upload-photo-button span {
  @media #{$media-md-max} {
    font-size: 0.9em;
  }
}

.Button-weightNormal {
  font-weight: 400;
  text-transform: none;
}

.Button--smallText {
  font-size: 0.75em;
  font-weight: 600;
}

.Button--flat {
  background-color: #fff;
  border: 1px solid $colors-gray-lighter !important;
  color: #dbdfe5;
  opacity: 1;
  cursor: auto;
}

.Button--yellow {
  border: solid 1px $sun;
  border-radius: $border-radius;
  background-color: $sun;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #001d58;
  }
  &:focus,
  &:hover {
    background-color: $sun;
    color: #001d58;
  }
}

.Button--yellow {
  border: solid 1px $sun;
  border-radius: $border-radius;
  background-color: $sun;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #001d58;
  }
  &:focus,
  &:hover {
    background-color: $sun-dark;
    color: #001d58;
  }
}

.Button--aquamarine {
  border: solid 1px $colors-captains;
  border-radius: $border-radius;
  background-color: $colors-captains;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
  &:focus,
  &:hover {
    background-color: #02949d;
    color: #fff;
  }
}

.Button--darkAquamarineOutline {
  border: solid 1px $bs-darkAquamarine;
  border-radius: $border-radius;
  background-color: '#fff';
  &,
  &:link,
  &:visited,
  &:focus,
  &:active {
    color: $bs-darkAquamarine;
  }
  &:focus,
  &:hover {
    background-color: $bs-darkAquamarine;
    color: #fff;
  }
}


.Button--link {
  border: none;
  color: $colors-primary;
  padding: 0 !important;
  text-transform: inherit;
  font-weight: 400;
  font-size: 1em;
  min-width: auto;
}

.Button--mdLg {
  height: 50px;
}

.Button-InviteCaptain {
  width: 136px !important;
}
