input[type='checkbox'],
.ascheckbox {
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  appearance: none;
  background-color: #fff;
  position: relative;
  outline: none;
  transition: all ease-in-out 0.2s;
  &.Checkbox--owners {
    background-color: $stone;
    border: 1px solid $mid-rock;
  }

  &.Checkbox--captains {
    background-color: $stone;
    border: 1px solid $mid-rock;
  }

  &.Checkbox--ownersSecondary {
    background-color: $stone;
    border: 1px solid $mid-rock;
  }

  &.Checkbox--small {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    border: 1px solid #979797;
  }
}

.ascheckbox {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 10px;
}

input[type='checkbox']:checked,
.ascheckbox.checked {
  border: 1px solid $colors-primary;
  background-color: $colors-primary;
  appearance: none;
  transition: all ease-in-out 0.2s;
  &.Checkbox--owners {
    background-color: #0a5551;
    border: 1px solid $sun;
  }

  &.Checkbox--ownersSecondary {
    background-color: white;
    border: 1px solid $sea-weed;
  }

  &.Checkbox--captains {
    background-color: $colors-captains;
    border: 1px solid $colors-captains;
  }
}

input[type='checkbox']::before,
input[type='checkbox']::after,
.ascheckbox::before,
.ascheckbox::after {
  margin: auto;
  content: '';
  display: block;
  position: absolute;
}

input[type='checkbox']::after,
.ascheckbox::after {
  opacity: 0;
  top: 0;
  left: 1px;
  right: 0;
  bottom: 2px;
  -webkit-transition: -webkit-transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  -webkit-transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  border-style: solid;
  border-color: #fff;
  width: 5px;
  height: 12px;
  border-width: 0 2px 2px 0;
  -webkit-transform: scale(0.5) rotate(45deg);
  -ms-transform: scale(0.5) rotate(45deg);
  transform: scale(0.5) rotate(45deg);
  &.Checkbox--owners {
    border-color: $sun;
  }

  &.Checkbox--ownersSecondary {
    border-color: $sea-weed;
  }

  &.Checkbox--captains {
    border-color: #fff;
  }
}

input[type='checkbox']:checked::after,
.ascheckbox.checked::after {
  opacity: 0;
  top: 0;
  left: 1px;
  right: 0;
  bottom: 2px;
  -webkit-transition: -webkit-transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  -webkit-transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  transition: transform ease-in-out 0.15s, opacity ease-in-out 0.15s;
  border-style: solid;
  border-color: #fff;
  width: 5px;
  height: 12px;
  border-width: 0 2px 2px 0;
  -webkit-transform: scale(0.5) rotate(45deg);
  -ms-transform: scale(0.5) rotate(45deg);
  transform: scale(0.5) rotate(45deg);
  opacity: 1;
  width: 5px;
  height: 12px;
  border-width: 0 2px 2px 0;
  -webkit-transform: scale(1) rotate(45deg);
  -ms-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}

input[type='checkbox'].Checkbox--owners:checked::after {
  border-color: $sun;
}

input[type='checkbox'].Checkbox--ownersSecondary:checked::after {
  border-color: $sea-weed;
}

input[type='checkbox'].Checkbox--captains:checked::after {
  border-color: #fff;
}

input[type='checkbox']:checked + label {
  color: $colors-primary;
}

input[type='checkbox'].Checkbox--owners:checked + label {
  color: $sea-weed;
}

input[type='checkbox'].Checkbox--ownersSecondary:checked + label {
  color: $sea-weed;
}

input[type='checkbox'].Checkbox--captains:checked + label {
  color: $sea-weed;
}

.Checkbox-option {
  background-color: $sea-weed;
  color: white;
  cursor: pointer;
  border-radius: $border-radius-bigm;
  @media #{$media-sm-max} {
    min-height: 90px;
  }
  &.Panel--noRoundBottom {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.Checkbox-option--big {
  border-radius: $border-radius-extrabig;
}
