//@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=224cb190-116d-42ae-8772-98e67496b11c&fontids=721263,721269,721275,721281");

@font-face {
  font-family: 'AvenirNext';
  font-display: swap;
  src: url('/images/fonts/avenir/721263/e91672383b3f4813a04aa384394eed42.eot?#iefix');
  src: url('/images/fonts/avenir/721263/e91672383b3f4813a04aa384394eed42.eot?#iefix') format('eot'),
    url('/images/fonts/avenir/721263/2cd55546ec004af9aeca4a3cd186da53.woff2') format('woff2'),
    url('/images/fonts/avenir/721263/1e9892c06927441298741b82801ba47a.woff') format('woff'),
    url('/images/fonts/avenir/721263/46cf1067688d4aabb0f7bd942af6efd8.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  font-display: swap;
  src: url('/images/fonts/avenir/721275/1a7c9181cd244943a9d9d033189524e0.eot?#iefix');
  src: url('/images/fonts/avenir/721275/1a7c9181cd244943a9d9d033189524e0.eot?#iefix') format('eot'),
    url('/images/fonts/avenir/721275/627fbb5a3bae4cd9b6172f923e29d55e.woff2') format('woff2'),
    url('/images/fonts/avenir/721275/f26faddb86cc4477a2531e1287684336.woff') format('woff'),
    url('/images/fonts/avenir/721275/63a74598733c4d0cbd91b01bffcd6e69.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  font-display: swap;
  src: url('/images/fonts/avenir/721269/12d643f2389949d5a85bff430f5fad15.eot?#iefix');
  src: url('/images/fonts/avenir/721269/12d643f2389949d5a85bff430f5fad15.eot?#iefix') format('eot'),
    url('/images/fonts/avenir/721269/aad99a1f79174dd6bbb5b07cedbff64f.woff2') format('woff2'),
    url('/images/fonts/avenir/721269/91b50bbb9aa14d549159ec6f19d14a7c.woff') format('woff'),
    url('/images/fonts/avenir/721269/a0f4c2f98a424786ad00fce42b57b148.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  font-display: swap;
  src: url('/images/fonts/avenir/721281/dccb10af07a2404cbfc77750e2716bc1.eot?#iefix');
  src: url('/images/fonts/avenir/721281/dccb10af07a2404cbfc77750e2716bc1.eot?#iefix') format('eot'),
    url('/images/fonts/avenir/721281/14c73713e4df4dba933b057feeac8dd1.woff2') format('woff2'),
    url('/images/fonts/avenir/721281/b8e906a1f5e84bf18e8082c646ca4d5f.woff') format('woff'),
    url('/images/fonts/avenir/721281/890bd988530643ffbd4b922bc5ebdeb4.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
