.BoatPhotoSlider {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: relative;
  height: 100%;
  &:hover {
    .BoatPhotoSlider-arrowNav {
      opacity: 1;
      visibility: visible;
      @media only screen and (max-width: 66em) {
        opacity: 0 !important;
        visibility: hidden;
      }
    }
  }
  .BoatPhotoSlider-slideNav {
    display: block !important;
  }
}

.BoatPhotoSlider-wrapper {
  position: relative;
  width: 100%;
  z-index: 0;
  border-radius: 8px;
  overflow: hidden;
  max-height: 434px;
  height: 100%;
}

.BoatPhotoSlider-wrapper-review {
  border-radius: 16px;
}

.BoatPhotoSlider-wrapper:before {
  padding-bottom: calc(3 / 4 * 100%);
  content: '';
  display: block;
  width: 100%;
}

.BoatPhotoSlider-wrapper--placeholder:before {
  padding-bottom: calc(3 / 4 * 50%);

  @media only screen and (max-width: 640px) {
    padding-bottom: calc(3 / 4 * 100%);
  }
}

.BoatPhotoSlider-content {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
}

.BoatPhotoSlider-slidesWrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.BoatPhotoSlider-slides {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.BoatPhotoSlider-imageContainer {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 100%;
}

.BoatPhotoSlider-imgWrapper {
  width: 100%;
  height: 100%;
}

.BoatPhotoSlider-img {
  position: absolute;
  border-radius: 8px;
  background-size: cover;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.BoatPhotoSlider-img-review {
  border-radius: 16px;
}


.PhotosUpload-Wrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.SortablePhotosUpload-Wrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PhotoUploader-zone,
.PhotoUploader-holder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  outline: none;
  border: none;
  cursor: pointer;
  justify-content: center;

  label {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;

    .u-textCenter {
      align-self: center;
    }
  }
}

.PhotoUploader-holder {
  cursor: default !important;
}
