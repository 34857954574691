.toggle-switch-wrapper {
  &:focus {
    outline: none;
  }
}
.toggle-switch {
  width: 59px;
  height: 36px;
  padding: 5px;
  padding-right: 6px;
  padding-left: 6px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #0751c2;
  border-radius: 5px;
  transition: color 280ms ease-in-out;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .toggle-switch-box {
    margin-left: 50%;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #0751c2;
    transition: all 280ms ease-in-out;
  }
  &.disabled {
    border: 1px solid #c0c0c0 !important;
    .toggle-switch-box {
      margin-left: 0;
      background: #c0c0c0 !important;
    }
  }
  &.with-switch-text {
    width: 111px;
  }
  &.is-green {
    border: 1px solid #2a8500;
    .toggle-switch-box {
      background: #2a8500;
    }
  }
}
.with-switch-text {
  width: 111px;
  .toggle-switch-box {
    margin-left: 75%;
  }
}

.switch-text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 9px;
  &.left {
    left: 12px;
    color: #2a8500;
  }
  &.right {
    right: 9px;
    color: #8e9697;
  }
}
