/** @define Select; weak */

.Select-root {
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.Select {
  position: relative;
  width: 100%;
}

.Select-label {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  display: block;
  transform-origin: top left;
  padding: 0;
  font-size: 1em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.Select__control {
  @include controlSelectSizeDefault;
  appearance: none;
  background: #fff;
  box-sizing: border-box;
  color: $colors-gray-medium;
  border: solid 1px $colors-border !important;
  border-radius: $border-radius !important;
  cursor: pointer;
  font: inherit;
  line-height: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 9px;
  height: 50px;
  transition: border 200ms, border-color 200ms;
}

.Select__value-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
}

.small .Select__control {
  @include controlSelectSizeDefault;
}

.Select-dark {
  .Select__control {
    background: rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(0, 0, 0, 0) !important;
    .Select__single-value {
      color: white;
    }
  }
  .Select__placeholder {
    color: rgba(255, 255, 255, 0.56);
  }
}

.Select__placeholder {
  font-size: 16px;
  color: $rock;
  transform: translate(0px, 0px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 100%;
}

.Select_notSearchable {
  .Select__placeholder {
    transform: translate(3px, 4px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
}

.Select__control--is-focused {
  .Select__placeholder {
    transform: translate(-12%, -12px) scale(0.75);
    color: $mid-rock;
    pointer-events: none;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
}

.Select_notSearchable {
  .Select__control--is-focused {
    .Select__placeholder {
      transform: translate(-12%, -9px) scale(0.75);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  }
}

.Select__value-container--has-value {
  .Select__placeholder {
    transform: translate(-12%, -12px) scale(0.75);
    color: $mid-rock;
    pointer-events: none;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
}

.Select_notSearchable {
  .Select__value-container--has-value {
    .Select__placeholder {
      transform: translate(-12%, -9px) scale(0.75);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  }
}

.Select.is-invalid {
  color: $colors-danger;
  .Select__control {
    border: solid 1px $colors-danger !important;
  }
  .Select__option {
    color: $colors-gray-medium;
  }
  &::placeholder {
    color: $sunset;
  }
}

.Select__option--is-focused {
  background-color: $stone !important;
}

.Select.owner {
  .Select__option--is-selected {
    background-color: $sea-weed !important;
    color: white !important;
  }
}

.Select.renter {
  .Select__option--is-selected {
    background-color: $water !important;
    color: white !important;
  }
}

.Select.captain {
  .Select__option--is-selected {
    background-color: $colors-captains !important;
    color: white !important;
  }
}

.Select__option--is-selected {
  background-color: $sea-weed !important;
  color: white !important;
}

.Select__control--is-focused {
  border-color: $mid-rock !important;
  box-shadow: 0 0 0 0px $mid-rock !important;
}
.Select-dark {
  .Select__control.Select__control--is-focused {
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

.Select__control option:disabled {
  color: $colors-gray-extra-light;
}

.Select__indicator-separator {
  display: none;
}

.Select__control:disabled {
  cursor: not-allowed;
}

.Select__control--naked {
  border: 0;
  border-radius: 0;
}

.Select__control.is-invalid {
  border-color: $colors-danger;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Select__menu {
  margin-top: 0px !important;
  z-index: 998 !important;
}

.Select__group-heading {
  color: $midnight !important;
  font-weight: 600 !important;
  cursor: default !important;
  text-transform: capitalize !important;
  font-size: 1em !important;
}

.Select__group .Select__option {
  padding-left: 25px;
}

.Select-figure {
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  color: $colors-primary;
}

.Select-icon {
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-50%);
  color: $colors-gray-light;
}

.Select__control::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.Select.is-disabled .Select__control {
  color: $colors-gray-light;
  cursor: default;
  opacity: 1;
}

.Select.is-disabled,
.Select:not(.is-disabled) .Select__control:disabled {
  opacity: 0.5;
}

.Select__control:focus {
  color: $colors-gray-light;
}

/**
 * Hide redundant text outline in Firefox
 */

.Select__control:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 $colors-gray-medium !important;
}

/**
 * Fallback to native arrow in IE<10, Opera < 9.6, and Firefox<35.
 * The double selector makes sure it wins over extended theming.
 */

.Select.Select .Select-figure {
  display: none\9;
}

x:-o-prefocus,
.Select.Select .Select-figure {
  display: none;
}

_:-moz-tree-row(hover),
.Select.Select .Select-figure {
  display: none;
}

@supports (filter: blur(0)) {
  _:-moz-tree-row(hover),
  .Select.Select .Select-figure {
    display: block;
  }
}

/**
 * Remove the native arrow and the blue background in IE 10/11+
 */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Select__control::-ms-expand {
    display: none;
  }

  .Select__control:focus::-ms-value {
    background: transparent;
    color: $colors-gray-light;
  }
}

// styles for booking widget
.Select--large {
  .Select__menu {
    margin-top: -200px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
  }
  .Select__control {
    border-radius: 10px !important;
  }
  .Select-figure i {
    color: $colors-primary !important;
  }
  .Select__control--is-focused {
    border-color: #dbdfe5 !important;
    box-shadow: 0 0 0 0px #fff !important;
  }
  .Select__menu-list {
    max-height: 250px;
  }
  .Select__option--is-selected {
    background-color: $colors-primary !important;
    color: white !important;
  }
}

.Select-dark {
  .Select__input {
    color: white !important;
    input {
      color: white !important;
    }
  }
}

.Select__single-value {
  margin-top: 8px;
  font-weight: 600;
  font-size: 1em;
}

.Select__input input {
  font-weight: 600 !important;
  font-size: 1em;
}

.Select-capitalizeFirstLetter {
  .Select__option {
    text-transform: lowercase;
  }

  .Select__option::first-letter {
    text-transform: uppercase !important;
  }

  .Select__single-value {
    text-transform: lowercase;
  }
  .Select__single-value::first-letter {
    text-transform: uppercase !important;
  }
}
