/** @define Divider; use strict */

.Divider {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: $colors-border;
  margin: 0;
}

.Divider--vertical {
  border-width: 0 0 0 2px;
}

.Divider--thin {
  border-width: 1px 0 0 0;
}

.Divider--vertical-thin {
  border-width: 0 0 0 1px;
}

.Divider--vertical-thin2 {
  border-width: 0 1px 0 0;
}

.Divider--vertical-thin-md-hide {
  border-width: 0 0 0 1px;
  @media #{$media-md-max} {
    border-width: 0 0 0 0px;
  }
}

.Divider--thin-md-show {
  @media #{$media-md-max} {
    border-width: 1px 0 0 0;
  }
}

.Divider--faint {
  border-color: $colors-border-faint;
}

.Divider--blue {
  border-color: transparent;
  border-bottom-color: $colors-primary;
  border-width: 1px;
}

.Divider--lightBlue {
  border-color: transparent;
  border-bottom-color: #bde3fd;
  border-width: 1px;
}

.Divider--grayDark {
  border-color: $colors-gray-dark;
}

.Divider--faded {
  border-color: rgba(255, 255, 255, 0.32);
}

.Divider--light {
  border-width: 0px;
  border-color: rgba(189, 227, 253, 0.768684);
  border-bottom-width: 1px;
}

.Divider--withGutter {
  margin: 0 calc(-2 * #{$grid-gutter-size});
}

.Divider--withGutterWide {
  margin: 0 calc(-3 * #{$grid-gutter-size});
}

@media #{$media-md-viewport} {
  .Divider--withGutter {
    margin: 0 calc(-2 * 16px);
  }
}

@media #{$media-sm-viewport} {
  .Divider--withGutter {
    margin: 0 calc(-1 * #{$grid-gutter-size});
  }
  .Divider--withGutterWide {
    margin: 0 calc(-1.5 * #{$grid-gutter-size});
  }
}

@media #{$ipad-pro-portrait-breakpoint} {
  .Divider--withGutterWide {
    margin: 0 calc(-1.3 * #{$grid-gutter-size});
  }
}

@media #{$media-md-viewport} {
  .Divider--withGutterWide {
    margin: 0 calc(-1.2 * #{$grid-gutter-size});
  }
}
@media #{$media-lg-viewport} {
  .Divider-lg-left1 {
    left: 1em;
  }
}

.Divider--vertical-thin-sm-hide {
  border-width: 0 0 0 1px;
  @media #{$media-sm-max} {
    border-width: 0 0 0 0px;
  }
}
