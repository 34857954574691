/** @define Select; weak */

.Select {
  position: relative;
  width: 100%;
  transition: opacity 300ms ease-in;
}

.Select-control {
  @include controlSelectSizeDefault;
  appearance: none;
  background: #fff;
  box-sizing: border-box;
  color: $colors-gray-medium;
  border: solid 1px $colors-border;
  border-radius: $border-radius;
  cursor: pointer;
  line-height: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-control option:disabled {
  color: $colors-gray-extra-light;
}

.Select-control:disabled {
  cursor: not-allowed;
}

.Select-control--naked {
  border: 0;
  border-radius: 0;
}

.Select-control--big {
  padding: 1.6em 1em;
  height: 70px;
  @media #{$media-sm-max} {
    padding: 1.6em 1em;
    height: 64px;
  }
}

.Select-control--borderless {
  border: 0;
}

.Select-control--withIcon {
  padding-left: 2.5em;
}

.Select-control--borderTopBottom {
  border-top: 1px solid $colors-border;
  border-bottom: 1px solid $colors-border;
}

.Select-control.is-invalid {
  border-color: $colors-danger;
  color: $colors-danger;
  &::placeholder {
    color: $sunset;
  }
}

.Select-control.is-invalid ~ .Select-figure {
  .selectIcon {
    color: $colors-danger;
  }
}

.Select-control--withIcon {
  padding-left: 2.5em;
}

.Select-figure {
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  color: $colors-primary;
}

.Select-dark {
  .Select-control {
    background: rgba(0, 0, 0, 0.16);
    border-color: rgba(0, 0, 0, 0) !important;
    color: white;
    &:focus {
      color: white;
    }
    option {
      background: white;
    }
  }
  .Select-control--notSelected {
    color: rgba(255, 255, 255, 1);
    &:focus {
      color: rgba(255, 255, 255, 1);
    }
  }
  .Select-figure {
    color: white;
  }
}

.Select-icon {
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-50%);
  color: $colors-gray-light;
}

.Select-control::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.Select.is-disabled .Select-control {
  color: $colors-gray-light;
  cursor: default;
  opacity: 1;
}

.Select.is-disabled,
.Select--is-disabled,
.Select:not(.is-disabled) .Select-control:disabled {
  opacity: 0.5;
  transition: opacity 300ms ease-in;
  cursor: not-allowed;
}

.Select__single-value--is-disabled {
  color: #17233c !important;
}
.Select-control:focus {
  color: $colors-gray-light;
}

/**
 * Hide redundant text outline in Firefox
 */

.Select-control:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 $colors-gray-medium !important;
}

/**
 * Fallback to native arrow in IE<10, Opera < 9.6, and Firefox<35.
 * The double selector makes sure it wins over extended theming.
 */

.Select.Select .Select-figure {
  display: none\9;
}

x:-o-prefocus,
.Select.Select .Select-figure {
  display: none;
}

_:-moz-tree-row(hover),
.Select.Select .Select-figure {
  display: none;
}

@supports (filter: blur(0)) {
  _:-moz-tree-row(hover),
  .Select.Select .Select-figure {
    display: block;
  }
}

/**
 * Remove the native arrow and the blue background in IE 10/11+
 */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Select-control::-ms-expand {
    display: none;
  }

  .Select-control:focus::-ms-value {
    background: transparent;
    color: $colors-gray-light;
  }
}

// html date input

.birthday-mobile-date-input {
  display: block;
  position: relative;
  z-index: 0;
  pointer-events: none;
  top: 0;
  left: 0;
  background-size: 14px;
  background-position: 20px center;
  background-repeat: no-repeat;
  &:focus {
    border: 1px solid #5e696a;
  }
}

select.Select-control:-webkit-autofill,
select.Select-control:-webkit-autofill:hover,
select.Select-control:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.Select-dark select.Select-control:-webkit-autofill,
.Select-dark select.Select-control:-webkit-autofill:hover,
.Select-dark select.Select-control:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  transition: background-color 5000s ease-in-out 0s;
}
