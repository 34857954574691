.u-w100 {
  width: 100%;
}

.u-h100 {
  height: 100%;
}




