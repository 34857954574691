.remodal-enter {
  opacity: 0.01;
  .remodal {
    transform: scale(0.95);
  }
}

.remodal-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  .remodal {
    transform: scale(1);
    transition: transform 300ms ease-in-out;
  }
}

.remodal-exit {
  opacity: 1;
  .remodal {
    transform: scale(1);
  }
}

.remodal-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in-out;
  .remodal {
    transform: scale(0.95);
    transition: transform 300ms ease-in-out;
  }
}

.remodalRight-enter {
  right: -100vw !important;
}

.remodalRight-enter-active {
  right: 0 !important;
  transition: right 500ms;
}

.remodalRight-exit {
  right: 0 !important;
}

.remodalRight-exit-active {
  right: -100vw !important;
  transition: right 500ms;
}

.remodalTray-enter {
  .remodal {
    transform: translateY(100vh);
  }
}

.remodalTray-enter-active {
  .remodal {
    transform: translateY(var(--modal-height-offset));
    transition: transform 300ms ease-in-out;
  }
}

.remodalTray-enter-done {
  .remodal {
    transform: inherit;
    transition: inherit;
    bottom: 0;
    top: 30vh;
    position: absolute !important;
  }
}

.remodalTray-exit {
  .remodal {
    transform: translateY(var(--modal-height-offset));
  }
}

.remodalTray-exit-active {
  .remodal {
    transform: translateY(100vh);
    transition: transform 300ms ease-in-out;
  }
}

.remodal-tray-wrapper {
  align-items: flex-end;
  -webkit-box-pack: end;
  -webkit-box-align: end;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  padding-top: 20px;
  display: flex !important;
  overflow: hidden !important;
}

.remodal-full-overlay-wrapper {
  align-items: flex-end;
  -webkit-box-pack: end;
  -webkit-box-align: end;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  display: flex;
  overflow: hidden !important;
}

.remodal-overlay-enter {
  opacity: 0.01;
  background: rgba(10, 37, 47, 0.6);
  display: block;
}

.remodal-overlay-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  background: rgba(10, 37, 47, 0.6);
  display: block;
}

.remodal-overlay-enter-done {
  display: block;
  background: rgba(10, 37, 47, 0.6);
}

.remodal-overlay-exit {
  opacity: 1;
  background: rgba(10, 37, 47, 0.6);
  display: block;
}

.remodal-overlay-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in-out;
  background: rgba(10, 37, 47, 0.6);
  display: block;
}

.remodal-overlay-exit-done {
  display: none;
}

.remodal-wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.remodal-tray {
  &.remodal-ios {
    // padding-bottom: calc(36px + 49px);
  }
}

.remodal-full-overlay {
  // &.remodal-ios {
  //   padding-bottom: calc(36px + 49px);
  // }
  @media (max-width: 640px) {
    padding-bottom: 0;
  }
}

@media (max-width: 640px) {
  .remodal-noTitle {
    .remodal-scroll {
      padding-top: 0;
    }
    .remodal-content {
      padding-top: 0;
    }
  }
}
