/** @define Panel; use strict */

.Panel {
  padding: 2em;
  border-radius: $border-radius-bigm;
  background-color: #fff;
  position: relative;
  width: 100%;
}

.Panel-blwEntry {
  height: 540px;
  @media #{$media-md-max} {
    height: inherit;
  }
  @media #{$media-sm-max} {
    height: inherit;
  }
}

.Panel-borderMedium {
  border-radius: $border-radius-bigm;
}

.Panel--shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.Panel--shadowLight {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
}

.Panel--primary {
  border: solid 1px $colors-primary;
  box-shadow: 0px 5px 22px -2px rgba(0, 0, 0, 0.08);
}

.Panel--primary.Panel--arrowRight {
  border: solid 1px $colors-primary;
  &:after,
  &:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #fff;
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(229, 233, 236, 0);
    border-left-color: $colors-primary;
    border-width: 13px;
    margin-top: -13px;
  }
}

.Panel--primary.Panel--arrowLeft {
  border: solid 1px $colors-primary;
  &:after,
  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(229, 233, 236, 0);
    border-right-color: $colors-primary;
    border-width: 13px;
    margin-top: -13px;
  }
}

.Panel--arrowTopLeft {
  border: solid 1px $colors-border;
}

.Panel--border {
  border: solid 1px $colors-border;
}

.Panel--arrowTopLeft:after,
.Panel--arrowTopLeft:before {
  bottom: 100%;
  left: 2em;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.Panel--arrowTopLeft:after {
  border-color: rgba(229, 233, 236, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.Panel--arrowTopLeft:before {
  border-color: rgba(229, 233, 236, 0);
  border-bottom-color: $colors-border;
  border-width: 13px;
  margin-left: -13px;
}

.Panel--arrowRight {
  border: 1px solid $colors-border;
  &:after,
  &:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #fff;
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(229, 233, 236, 0);
    border-left-color: $colors-border;
    border-width: 13px;
    margin-top: -13px;
  }
}

.Panel--arrowRight-noArrowMobile {
  @media #{$media-sm-max} {
    &:after,
    &:before {
      display: none !important;
    }
  }
}

.Panel--arrowLeft {
  border: 1px solid $colors-border;
  &:after,
  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(229, 233, 236, 0);
    border-right-color: $colors-border;
    border-width: 13px;
    margin-top: -13px;
  }
}

.Panel--compact {
  padding: 0;
}

.Panel--semiCompact {
  padding: 1em;
}

.Panel-compactSection {
  padding: 0.75em 1.5em;
  border-bottom: solid 2px $colors-border;
  &:last-child {
    border-bottom: none;
  }
}

.Panel--muted {
  background-color: $colors-border-light;
}

.Panel--dark {
  background-color: $colors-bg-body;
}

.Panel--lightBlue {
  border: 1px solid $colors-blue-light;
  background: $colors-blue-lighter;
}

.Panel--darkBlue {
  background-color: $colors-primary-darker;
}

.Panel--outline {
  border: solid 1px $colors-border;
}

.Panel--fadeDark {
  background: rgba(0, 0, 0, 0.16);
}

.Panel--dashed {
  border: dashed 2px $colors-border;
  &.dz-drag-hover {
    border-color: $colors-primary;
  }
}

.Panel--dashed--active {
  border: dashed 2px $sea-weed;

  &.dz-drag-hover {
    border-color: $colors-primary;
  }
}

.Panel--lg {
  padding: 2em 1em;
}

.Panel--paddingBig {
  padding: 3.125em;
  @media #{$media-sm-max} {
    padding: 1.625em;
  }
}

.Panel--paddingLarge {
  padding: 3em;
  @media #{$media-sm-max} {
    padding: 1.625em;
  }
}

.Panel--paddingMedium {
  padding: 2.5em;
  @media #{$media-sm-max} {
    padding: 1.625em;
  }
}

.Panel--paddingSmall {
  padding: 1.5em;
  @media #{$media-sm-max} {
    padding: 1.625em;
  }
}

.Panel--paddingExtraSmall {
  padding: 1em;
  @media #{$media-sm-max} {
    padding: 0.875em;
  }
}

.Panel-section {
  padding: 2em;
  border-bottom: solid 2px $colors-border;
  &:last-child {
    border-bottom: none;
  }
}

.Panel-sectionBig {
  padding: 2.625em 3em;
  border-bottom: solid 1px $colors-border;
  &:last-child {
    border-bottom: solid 0px $colors-border;
  }
}

.Panel-sectionSmall {
  padding: 1em;
  border-bottom: solid 1px $colors-border;
  &:last-child {
    border-bottom: solid 0px $colors-border;
  }
  @media #{$media-sm-max} {
    padding: 0em;
  }
}

.Panel-sectionBig--compact {
  padding: 0em;
}

.Panel-section:first-child {
  padding-top: 0;
}

.Panel-section:last-child {
  padding-bottom: 0;
}

.Panel--highlightedGreen {
  background: $colors-owners;
  color: white;
  p {
    color: white;
  }
}

.Panel--light {
  background: #fafdff;
  border: 1px solid rgba(189, 227, 253, 0.768684);
  box-shadow: 0px 5px 22px -2px rgba(0, 0, 0, 0);
}

@media #{$media-sm-max} {
  .sm-Panel {
    padding: 2em;
    border-radius: $border-radius-big;
    background-color: #fff;
    position: relative;
    margin-bottom: 2em;
  }
  .sm-Panel--light {
    background: #fafdff;
    border: 1px solid rgba(189, 227, 253, 0.768684);
    box-shadow: 0px 5px 22px -2px rgba(0, 0, 0, 0);
  }

  .Panel-sm--Compact {
    padding: 1em;
  }

  .Panel-sm--SemiCompact {
    padding: 0.5em;
  }

  .Panel--sm-noRoundBottom {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 2.375em 1.925em 3em;
  }

  .Panel--sm-spacing {
    padding: 1.625em 1.625em;
  }

  .Panel--sm-Compact {
    padding-top: 29px;
    padding-bottom: 29px;
  }
}

.Panel--noRoundTop {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.Panel--noRoundBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.Panel--sm-roundTop {
  border-top-left-radius: $border-radius-extrabig;
  border-top-right-radius: $border-radius-extrabig;
}

.Panel--stretch {
  @media #{$media-sm-max} {
    padding: 1em;
  }
}

.sm-Panel .remodal-close {
  top: 0;
}

.Panel--sun {
  border: solid 2px $sun;
}

.Panel-wrapperPDP {
  a > div {
    width: 64px;
    height: 64px;
  }
}
