

/* bottom */
.u-mb0 {
  margin-bottom: 0;
}

.u-mb05 {
  margin-bottom: 0.5em;
}

.u-mb075 {
  margin-bottom: 0.75em;
}

.u-mb175 {
  margin-bottom: 1.75em;
}

.u-mb2_375 {
  margin-bottom: 2.375em;
}

.u-mt08 {
  margin-top: 0.8em;
}

.u-mb08 {
  margin-bottom: 0.8em;
}

.u-mb08 {
  margin-bottom: 0.8em;
}

.u-mb1 {
  margin-bottom: 1em;
}

.u-mb1_25 {
  margin-bottom: 1.25em;
}

.u-mb1_5 {
  margin-bottom: 1.5em;
}

.u-mb1_56 {
  margin-bottom: 1.5625em;
}

.u-mb1_875 {
  margin-bottom: 1.875em;
}

.u-mb2 {
  margin-bottom: 2em;
}

.u-mb2_5 {
  margin-bottom: 2.5em;
}

.u-mb3 {
  margin-bottom: 3em;
}

.u-mb4 {
  margin-bottom: 4em;
}

.u-mb13 {
  margin-bottom: 13em;
}

.u-mt02 {
  margin-top: 0.2em;
}

.u-mt03 {
  margin-top: 0.3em;
}

.u-mt1 {
  margin-top: 1em;
}

.u-mt1_5 {
  margin-top: 1.5em;
}

.u-mt2 {
  margin-top: 2em;
}

.u-mt2_5 {
  margin-top: 2.5em;
}

.u-mt3 {
  margin-top: 3em;
}

.u-mtm3 {
  margin-top: -3em;
}

.u-mt4 {
  margin-top: 5em;
}

.u-mt5 {
  margin-top: 5em;
}

.u-mt7 {
  margin-top: 7em;
}

.u-mt10 {
  margin-top: 10em;
}

.u-mb3 {
  margin-bottom: 3em;
}

.u-mb5 {
  margin-bottom: 5em;
}

.u-mb7 {
  margin-bottom: 7em;
}

.u-mb8 {
  margin-bottom: 8em;
}

.u-mb9 {
  margin-bottom: 9em;
}


/* Top */

.u-mt05 {
  margin-top: 0.5em;
}

.u-md-mt0 {
  @media #{$media-md-max} {
    margin-top: 0em;
  }
}

.u-md-mt2 {
  @media #{$media-md-max} {
    margin-top: 2em;
  }
}

.u-sm-mt0 {
  @media #{$media-sm-max} {
    margin-top: 0em;
  }
}

.u-sm-mt02 {
  @media #{$media-sm-max} {
    margin-top: 0.2em;
  }
}

.u-sm-mt05 {
  @media #{$media-sm-max} {
    margin-top: 0.5em;
  }
}

.u-sm-mt1 {
  @media #{$media-sm-max} {
    margin-top: 1em;
  }
}

.u-sm-mt2 {
  @media #{$media-sm-max} {
    margin-top: 2em;
  }
}

.u-sm-mt0_313 {
  @media #{$media-sm-max} {
    margin-top: 0.313em;
  }
}

.u-sm-mt15 {
  @media #{$media-sm-max} {
    margin-top: 15em;
  }
}

/* Left */

.u-pl2_5 {
  padding-left: 2.5em !important;
}

.u-pl1 {
  padding-left: 1em;
}

.u-pl1_5 {
  padding-left: 1.5em;
}

.u-pl2 {
  padding-left: 2em;
}

.u-pl3 {
  padding-left: 3em;
}

.u-pl4 {
  padding-left: 4em;
}

.u-ml0 {
  margin-left: 0em;
}

.u-ml-auto {
  margin-left: auto;
}

.u-ml03 {
  margin-left: 0.3em;
}

.u-ml025 {
  margin-left: 0.25em;
}

.u-ml05 {
  margin-left: 0.5em;
}
.u-ml1 {
  margin-left: 1em;
}
.u-ml1_3 {
  margin-left: 1.3em;
}
.u-ml1_4 {
  margin-left: 1.4em;
}
.u-ml1_5 {
  margin-left: 1.5em;
}
.u-ml1_6 {
  margin-left: 1.6em;
}
.u-ml1_8 {
  margin-left: 1.8em;
}
.u-ml2 {
  margin-left: 2em;
}
.u-ml3 {
  margin-left: 3em;
}
.u-ml22 {
  margin-left: 22px;
}
/* right */
.u-mr0 {
  margin-right: 0;
}

.u-mr0875 {
  margin-right: 0.875em;
}

.u-mr1_5 {
  margin-right: 1.5em;
}

.u-mr1_4 {
  margin-right: 1.4em;
}

.u-mr05 {
  margin-right: 0.5em;
}

.u-mr025 {
  margin-right: 0.25em;
}

.u-mr1 {
  margin-right: 1em;
}

.u-mr2 {
  margin-right: 2em;
}

.u-mr3 {
  margin-right: 3em;
}

.u-mr5 {
  margin-right: 5em;
}

/* padding */
.u-p0 {
  padding: 0em;
}

.u-p025 {
  padding: 0.25em;
}

.u-p05 {
  padding: 0.5em;
}

.u-p02 {
  padding: 0.2em;
}

.u-p075 {
  padding: 0.75em;
}

.u-p1 {
  padding: 1em;
}

.u-p2 {
  padding: 2em;
}

.u-p3 {
  padding: 3em;
}

.u-p1_5 {
  padding: 1.5em;
}

.u-phz02 {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.u-phz05 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.u-phz1 {
  padding-left: 1em;
  padding-right: 1em;
}

.u-phz075 {
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.u-phz175 {
  padding-left: 1.75em;
  padding-right: 1.75em;
}

.u-phz2 {
  padding-left: 2em;
  padding-right: 2em;
}

.u-phz2_5 {
  padding-left: 2.5em;
  padding-right: 2.5em;
}

.u-phz3 {
  padding-left: 3em;
  padding-right: 3em;
}

.u-phz4 {
  padding-left: 4em;
  padding-right: 4em;
}

.u-phz5_5 {
  padding-left: 5.5em;
  padding-right: 5.5em;
}

.u-phz6 {
  padding-left: 6em;
  padding-right: 6em;
}

.u-pt0 {
  padding-top: 0em;
}

.u-pt05 {
  padding-top: 0.5em;
}

.u-pt1 {
  padding-top: 1em;
}

.u-pt05-i {
  padding-top: 0.5em !important;
}

.u-pt1_5 {
  padding-top: 1.5em;
}

.u-pt2 {
  padding-top: 2em;
}

.u-pt4 {
  padding-top: 4em;
}

.u-pt5 {
  padding-top: 5em;
}

.u-pt12 {
  padding-top: 12em;
}

.u-pb0 {
  padding-bottom: 0em;
}

.u-pb05 {
  padding-bottom: 0.5em;
}

.u-pb1 {
  padding-bottom: 1em;
}

.u-pb1_5 {
  padding-bottom: 1.5em;
}

.u-pb2 {
  padding-bottom: 2em;
}

.u-pb2_375 {
  padding-bottom: 2.5em;
}

.u-pb2_5 {
  padding-bottom: 2.5em;
}

.u-pb3 {
  padding-bottom: 3em;
}

.u-pb4 {
  padding-bottom: 4em;
}

.u-pb5 {
  padding-bottom: 5em;
}

.u-pv10 {
  padding-top: 10em;
  padding-bottom: 10em;
}

.u-pv6 {
  padding-top: 6em;
  padding-bottom: 6em;
}

.u-pv5 {
  padding-top: 5em;
  padding-bottom: 5em;
}

.u-pv4 {
  padding-top: 4em;
  padding-bottom: 4em;
}

.u-pv3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.u-pv2 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.u-pv2_5 {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

.u-pv1_5 {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.u-phz1_5 {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.u-phz1_3 {
  padding-left: 1.3em;
  padding-right: 1.3em;
}

.u-pv1_25 {
  padding-top: 1.3em;
  padding-bottom: 1.3em;
}

.u-pv1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.u-pv075 {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.u-pv0 {
  padding-top: 0em;
  padding-bottom: 0em;
}

.u-pv05 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.u-pv025 {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.u-pr0 {
  padding-right: 0 !important;
}

.u-pr05 {
  padding-right: 0.5em;
}

.u-pr1 {
  padding-right: 1em;
}

.u-pr1_5 {
  padding-right: 1.5em;
}

.u-pr145 {
  padding-right: 1.45em;
}

.u-pr2 {
  padding-right: 2em;
}

.u-pr3 {
  padding-right: 3em;
}

.u-pr4 {
  padding-right: 4em;
}

.u-pr5 {
  padding-right: 5em;
}

.u-pl075 {
  padding-left: 0.75em;
}

.u-pl05 {
  padding-left: 0.5em;
}

.u-pl025 {
  padding-left: 0.25em;
}

.u-pl01 {
  padding-left: 0.15625em !important;
}

@media #{$media-md-max} {
  .u-md-pv1 {
    padding-top: 0em;
    padding-bottom: 0em;
  }

  .u-md-pv1 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .u-md-pv2 {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .u-md-pv3 {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .u-md-phz05 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .u-md-phz2 {
    padding-left: 2em;
    padding-right: 2em;
  }

  .u-md-phz1_5 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .u-md-phz1 {
    padding-left: 1em;
    padding-right: 1em;
  }

  .u-md-phz0 {
    padding-left: 0em;
    padding-right: 0em;
  }

  .u-md-mb05 {
    margin-bottom: 0.5em;
  }

  .u-md-mb0 {
    margin-bottom: 0em;
  }

  .u-md-mb1 {
    margin-bottom: 1em;
  }

  .u-md-mb2 {
    margin-bottom: 2em;
  }

  .u-md-mb3 {
    margin-bottom: 3em;
  }

  .u-md-mb6 {
    margin-bottom: 6em;
  }

  .u-md-mb7 {
    margin-bottom: 7em;
  }

  .u-md-pt1 {
    padding-top: 1em;
  }

  .u-md-pt1_5 {
    padding-top: 1.5em;
  }

  .u-md-pt0 {
    padding-top: 0;
  }

  .u-md-pb0 {
    padding-bottom: 0;
  }

  .u-md-mr0 {
    margin-right: 0;
  }

  .u-md-pl0 {
    padding-left: 0;
  }

  .u-md-pl05 {
    padding-left: 0.5em;
  }

  .u-md-pl1 {
    padding-left: 1em;
  }

  .u-md-pv6 {
    padding-top: 6em;
    padding-bottom: 6em;
  }

  .u-md-pr05 {
    padding-right: 0.5em;
  }

  .u-md-pr0 {
    padding-right: 0em;
  }
}

@media #{$media-md-max} {
  .u-md-mb1 {
    margin-bottom: 1em;
  }

  .u-md-mb3 {
    margin-bottom: 3em;
  }
  .u-md-mb1 {
    margin-bottom: 1em;
  }
  .u-md-mb1_5 {
    margin-bottom: 1.5em;
  }
  .u-md-mt6 {
    margin-bottom: 6em;
  }
  .u-md-pt2 {
    padding-top: 2em;
  }
  .u-md-pt3 {
    padding-top: 3em;
  }
  .u-md-mt3_5 {
    margin-top: 3.5em;
  }
  .u-md-pb1 {
    padding-bottom: 1em;
  }
  .u-md-pb2 {
    padding-bottom: 2em;
  }
  .u-md-pb16 {
    padding-bottom: 16em;
  }

  .u-md-pb20 {
    padding-bottom: 20em;
  }

  .u-md-pb7 {
    padding-bottom: 7em;
  }
}

@media #{$media-size-lg-viewport} {
  .u-lg-min-pr2 {
    padding-right: 2em;
  }
}

@media #{$media-lg-viewport} {
  .u-lg-pv5 {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .u-lg-pv5 {
    padding-top: 5em;
    padding-bottom: 5em;
  }
  .u-lg-pv4 {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .u-lg-p2 {
    padding: 2em;
  }

  .u-lg-pr2 {
    padding-right: 2em;
  }

  .u-lg-pr3 {
    padding-right: 3em;
  }

  .u-lg-pr4 {
    padding-right: 3em;
  }

  .u-lg-phz2 {
    padding-left: 2em;
    padding-right: 2em;
  }
  .u-lg-phz10 {
    padding-left: 10em;
    padding-right: 10em;
  }

  .u-lg-mr05 {
    margin-right: 0.5em;
  }

  .u-lg-mr1 {
    margin-right: 1em;
  }

  .u-lg-mr1_5 {
    margin-right: 1.5em;
  }

  .u-lg-pv2 {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .u-lg-mb0 {
    margin-bottom: 0;
  }

  .u-lg-mr5 {
    margin-right: 5em;
  }

  .u-lg-mt4 {
    margin-top: 4em;
  }

  .u-lg-mt20vh {
    margin-top: 20vh;
  }

  .u-lg-mb1_5 {
    margin-bottom: 1.5625em;
  }

  .u-lg-mb1 {
    margin-bottom: 1em;
  }

  .u-lg-mb3_5 {
    margin-bottom: 3.5em;
  }

  .u-lg-pr1 {
    padding-right: 1em;
  }

  .u-lg-pr4 {
    padding-right: 4em;
  }

  .u-lg-pr7 {
    padding-right: 7em;
  }

  .u-lg-pl4 {
    padding-left: 4em;
  }

  .u-lg-pl1 {
    padding-left: 1em;
  }

  .u-lg-ml3 {
    margin-left: 3em;
  }

  .u-lg-pt0 {
    padding-top: 0em;
  }

  .u-lg-pb0 {
    padding-bottom: 0em;
  }

  u-lg-pt2 {
    padding-top: 2em;
  }
}

@media #{$ipad-pro-portrait-breakpoint} {
  .u-ip-pt1_5 {
    padding-top: 1.5em;
  }

  .u-ip-pt3 {
    padding-top: 3em;
  }

  .u-ip-pt4 {
    padding-top: 4em;
  }

  .u-ip-mb5 {
    margin-bottom: 5em;
  }

  .u-ip-mb3 {
    margin-bottom: 3em;
  }

  .u-ip-mb2 {
    margin-bottom: 2em;
  }

  .u-ip-mb05 {
    margin-bottom: 0.5em;
  }

  .u-ip-mt0 {
    margin-top: 0em;
  }

  .u-ip-pv0 {
    padding-top: 0em;
    padding-bottom: 0em;
  }

  .u-ip-pr0 {
    padding-right: 0em;
  }
}

@media #{$media-sm-max} {
  .u-sm-floatLeft {
    float: left !important;
  }

  .u-sm-floatNone {
    float: none !important;
  }

  .u-sm-mr1_5 {
    margin-right: 1.5em;
  }

  .u-sm-mr0 {
    margin-right: 0;
  }

  .u-sm-mr05 {
    margin-right: 0.5em;
  }

  .u-sm-mr2 {
    margin-right: 2em;
  }

  .u-sm-mr1 {
    margin-right: 1em;
  }

  .u-sm-p0 {
    padding: 0;
  }

  .u-sm-pl1 {
    padding-left: 1em;
  }

  .u-sm-p1 {
    padding: 1em;
  }

  .u-sm-p2 {
    padding: 2em;
  }

  .u-sm-pl05 {
    padding-left: 0.5em;
  }

  .u-sm-pl0 {
    padding-left: 0em;
  }

  .u-sm-pr3 {
    padding-right: 3em;
  }

  .u-sm-pr05 {
    padding-right: 0.5em;
  }

  .u-sm-ph1 {
    padding-left: 1em;
    padding-right: 1em;
  }
  .u-sm-pv1 {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .u-sm-pv2 {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .u-sm-pv4 {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .u-sm-pv6 {
    padding-top: 6em;
    padding-bottom: 6em;
  }

  .u-sm-pv8 {
    padding-top: 8em;
    padding-bottom: 8em;
  }

  .u-sm-pv0 {
    padding-top: 0em;
    padding-bottom: 0em;
  }

  .u-sm-mb05 {
    margin-bottom: 0.5em;
  }

  .u-sm-mb075 {
    margin-bottom: 0.75em;
  }

  .u-sm-mb1 {
    margin-bottom: 1em;
  }

  .u-sm-mb1_25 {
    margin-bottom: 1.25em;
  }

  .u-sm-mb1_5 {
    margin-bottom: 1.5em;
  }

  .u-sm-mb2 {
    margin-bottom: 2em;
  }

  .u-sm-mb2_5 {
    margin-bottom: 2.5em;
  }

  .u-sm-mb6 {
    margin-bottom: 6em;
  }

  .u-sm-mb6_5 {
    margin-bottom: 6.5em;
  }

  .u-sm-mb7 {
    margin-bottom: 7em;
  }

  .u-sm-mb9 {
    margin-bottom: 9em;
  }

  .u-sm-phz0 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-sm-phz05 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .u-sm-phz0_75 {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }

  .u-sm-phz1 {
    padding-left: 1em;
    padding-right: 1em;
  }

  .u-sm-phz1_5 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .u-sm-phz1_75 {
    padding-left: 1.75em;
    padding-right: 1.75em;
  }

  .u-sm-phz1_25 {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }

  .u-sm-phz2 {
    padding-left: 2em;
    padding-right: 2em;
  }

  .u-sm-phz2_2 {
    padding-left: 2.2em;
    padding-right: 2.2em;
  }

  .u-sm-pt38px {
    padding-top: 38px;
  }
}


.u-sm-mb2 {
  @media #{$media-sm-max} {
    margin-bottom: 2em;
  }
}


.u-sm-pt2 {
  @media #{$media-sm-max} {
    padding-top: 2em;
  }
}

.u-sm-pt1 {
  @media #{$media-sm-max} {
    padding-top: 1em;
  }
}

.u-sm-pt1_5 {
  @media #{$media-sm-max} {
    padding-top: 1.5em;
  }
}

.u-sm-pt0 {
  @media #{$media-sm-max} {
    padding-top: 0em;
  }
}
@media #{$media-sm-max} {
  .u-sm-pb10 {
    padding-bottom: 10em !important;
  }
}
.u-sm-pb16 {
  @media #{$media-sm-max} {
    padding-bottom: 16em;
  }
}

/* add more as needed... */

@media #{$media-sm-max} {
  .u-sm-mb0 {
    margin-bottom: 0;
  }



  .u-sm-mb05 {
    margin-bottom: 0.5em;
  }

  .u-sm-mb0625 {
    margin-bottom: 0.625em;
  }
  .u-sm-mb1 {
    margin-bottom: 1em;
  }

  .u-sm-mb24px {
    margin-bottom: 24px;
  }

  .u-sm-mt38px {
    margin-top: 38px;
  }

  .u-sm-mb38px {
    margin-bottom: 38px;
  }
  .u-sm-mb5 {
    margin-bottom: 5em !important;
  }

  .u-sm-mb10 {
    margin-bottom: 10em !important;
  }

  .u-sm-pb0 {
    padding-bottom: 0;
  }

  .u-sm-pb1 {
    padding-bottom: 1em;
  }

  .u-sm-pb1 {
    padding-bottom: 2em;
  }

  .u-sm-pb12 {
    padding-bottom: 12em;
  }

  .u-sm-pb5 {
    padding-bottom: 5em !important;
  }
  .u-sm-pb3 {
    padding-bottom: 3em !important;
  }

  .u-sm-pb2 {
    padding-bottom: 2em !important;
  }

  .u-sm-pb2_5 {
    padding-bottom: 2.5em !important;
  }

  .u-sm-pb6 {
    padding-bottom: 6em !important;
  }

  .u-sm-pb20 {
    padding-bottom: 20em;
  }

  .u-sm-pr0 {
    padding-right: 0em;
  }

  .u-sm-pr1 {
    padding-right: 1em;
  }

}

