/* font sizes */

$font-size-9: 0.5625em; /* 9px */
$font-size-10: 0.625em; /* 10px */
$font-size-10_5: 0.65em; /* 10.5px */
$font-size-11: 0.6875em; /* 11px */
$font-size-12: 0.75em; /* 12px */
$font-size-13: 0.813em; /* 12px */
$font-size-14: 0.875em; /* 14px */
$font-size-15: 0.9375em; /* 15px */
$font-size-18: 1.125em; /* 18px */
$font-size-19: 1.188em; /* 18px */
$font-size-20: 1.25em; /* 20px */
$font-size-21: 1.313em; /* 22px */
$font-size-22: 1.375em; /* 22px */
$font-size-24: 1.5em; /* 24px */
$font-size-26: 1.6em; /* 26px */
$font-size-28: 1.75em; /* 26px */
$font-size-30: 1.875em; /* 30px */
$font-size-32: 2em; /* 32px */
$font-size-38: 2.375em; /* 32px */
$font-size-40: 2.5em; /* 40px */
$font-size-48: 3em; /* 48px */
$font-size-56: 3.5em; /* 56px */
$font-size-64: 4em; /* 64px */

@mixin fontSize9 {
  font-size: $font-size-9;
  line-height: 1.7;
}

@mixin fontSize10 {
  font-size: $font-size-10;
  line-height: 1.7;
}

@mixin fontSize10_5 {
  font-size: $font-size-10_5;
  line-height: 1.7;
}

@mixin fontSize11 {
  font-size: $font-size-11;
  line-height: 1.6;
}

@mixin fontSize12 {
  font-size: $font-size-12;
  line-height: 1.5; /* 18px */
}

@mixin fontSize13 {
  font-size: $font-size-13;
  line-height: 1.5; /* 18px */
}

@mixin fontSize14 {
  font-size: $font-size-14;
  line-height: 1.714285; /* 24px */
}

@mixin fontSize15 {
  font-size: $font-size-15;
  line-height: 1.714285; /* 24px */
}

@mixin fontSize18 {
  font-size: $font-size-18;
  line-height: 1.333333; /* 24px */
}

@mixin fontSize19 {
  font-size: $font-size-19;
  line-height: 1.333333; /* 24px */
}

@mixin fontSize20 {
  font-size: $font-size-20;
  line-height: 1.4; /* 32px */
}

@mixin fontSize21 {
  font-size: $font-size-21;
  line-height: 1.4; /* 32px */
}

@mixin fontSize22 {
  font-size: $font-size-22;
  line-height: 1.7; /* 32px */
}

@mixin fontSize22 {
  font-size: $font-size-22;
  line-height: 1.6; /* 32px */
}

@mixin fontSize24 {
  font-size: $font-size-24;
  line-height: 1.333333;
}

@mixin fontSize26 {
  font-size: $font-size-26;
  line-height: 1.333333; /* 32px */
}

@mixin fontSize28 {
  font-size: $font-size-28;
  line-height: 1.333333; /* 32px */
}

@mixin fontSize30 {
  font-size: $font-size-30;
  line-height: 1.333333;
}

@mixin fontSize32 {
  font-size: $font-size-32;
  line-height: 1.3; /* 40px */
}

@mixin fontSize38 {
  font-size: $font-size-38;
  line-height: 1.333333;
}

@mixin fontSize40 {
  font-size: $font-size-40;
  line-height: 1.333333;
}

@mixin fontSize48 {
  font-size: $font-size-48;
  line-height: 1.333333; /* 64px */
}

@mixin fontSize56 {
  font-size: $font-size-56;
  line-height: 1; /* 64px */
}

@mixin fontSize64 {
  font-size: $font-size-64;
  line-height: 1; /* 80px */
}

.u-fsXxtiny {
  @include fontSize9;
  margin-bottom: 0;
}

.u-fsXtiny {
  @include fontSize10;
  margin-bottom: 0;
}

.u-fsSmTiny {
  @include fontSize10_5;
  margin-bottom: 0;
}

.u-fsTiny {
  @include fontSize12;
  margin-bottom: 0;
}

.u-fsExtraTiny {
  @include fontSize11;
  margin-bottom: 0;
}

.u-fsSuperTiny {
  @include fontSize10;
  margin-bottom: 0;
}

.u-fsSmm {
  @include fontSize13;
  margin-bottom: 0;
}

.u-fsSm {
  @include fontSize14;
  margin-bottom: 0;
}

.u-fsSmMd {
  @include fontSize15;
  margin-bottom: 0;
}

.u-fsMd {
  @include fontSize18;
  margin-bottom: 0;
}

.u-fsMdX {
  @include fontSize18;
  margin-bottom: 0;
}

.u-fsLg {
  @include fontSize20;
  margin-bottom: 0;
}

.u-fsLgM {
  @include fontSize21;
  margin-bottom: 0;
  letter-spacing: normal;
}

.u-fsMl {
  @include fontSize22;
  margin-bottom: 0;
}

.u-fsXl {
  @include fontSize24;
  margin-bottom: 0;
}

.u-fsXlmd {
  @include fontSize28;
  margin-bottom: 0;
}

.u-fsXllg {
  @include fontSize30;
  margin-bottom: 0;
}

.u-fsXxl {
  @include fontSize32;
  margin-bottom: 0;
}

.u-fsXxlmd {
  @include fontSize40;
  margin-bottom: 0;
}

.u-fsXxlSm {
  @include fontSize38;
  margin-bottom: 0;
}

.u-fsXxxl {
  @include fontSize48;
  margin-bottom: 0;
}

.u-fsBase {
  font-size: 1em;
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 768px) {
  .u-md-fsXs {
    @include fontSize12;
    margin-bottom: 0;
  }
  .u-md-fsLg {
    @include fontSize26;
    margin-bottom: 0;
  }

  .u-md-fsXl {
    @include fontSize32;
    margin-bottom: 0;
  }
}

@media (max-width: 640px) {
  .u-sm-fsBase {
    font-size: 1em;
    line-height: 1.5;
    margin: 0;
  }

  .u-sm-fsSm {
    font-size: 1.4em;
    margin-bottom: 0;
  }

  .u-sm-fsXl {
    @include fontSize24;
    margin-bottom: 0;
  }

  .u-sm-fsMdX {
    @include fontSize18;
    margin-bottom: 0;
  }

  .u-sm-fsXll {
    @include fontSize26;
    margin-bottom: 0;
  }

  .u-sm-fsXxl {
    @include fontSize32;
    margin-bottom: 0;
  }

  .u-sm-fsLg {
    @include fontSize20;
    margin-bottom: 0;
  }

  .u-sm-fsLgM {
    @include fontSize21;
    margin-bottom: 0;
  }

  .u-sm-fsMd {
    font-size: 2em;
    margin-bottom: 0;
  }

  .u-sm-fsSmm {
    font-size: 1.3em;
    margin-bottom: 0;
  }

  .u-sm-fsXm {
    font-size: 1.8em;
    margin-bottom: 0;
  }

  .u-sm-fsXmm {
    @include fontSize28;
    margin-bottom: 0;
  }

  .u-sm-fsMl {
    @include fontSize22;
    margin-bottom: 0;
  }

  .u-sm-fsXs {
    font-size: 1em;
  }

  .u-sm-fsExtraTiny {
    @include fontSize11;
    margin-bottom: 0;
  }

  .u-sm-fsXsm {
    font-size: 0.92em;
    margin-bottom: 0;
  }

  .u-sm-fsXxs {
    font-size: 0.8em;
  }

  .u-sm-fsXxxs {
    font-size: 0.75em;
  }

  .u-sm-fsTiny {
    @include fontSize12;
    margin-bottom: 0;
  }

  .u-sm-fsXxtiny {
    @include fontSize9;
    margin-bottom: 0;
  }
}

@media (max-width: 375px) {
  .u-xs-fsTiny {
    font-size: 0.7em;
  }
}

@media (max-width: 320px) {
  .u-xxs-fsTiny {
    font-size: 0.7em;
    @include fontSize12;
    margin-bottom: 0;
  }
}

