/* DEFAULT COLOR VARIABLES */

$ball-color: #ffffff;
$active-color: #0751c2;
$inactive-color: #cccccc;

/* DEFAULT SIZING VARIABLES */

$switch-size: 40px;
$ball-spacing: 5px;
$stretch-factor: 2.5;
$stretch-factor-mobile: 2;

/* DEFAULT CLASS VARIABLE */

$switch-class: 'switch-toggle';

/* STYLES */

@mixin switch(
  $size: $switch-size,
  $spacing: $ball-spacing,
  $stretch: $stretch-factor,
  $color: $active-color,
  $class: $switch-class
) {
  // SELECTOR VARIABLES
  $self: '.' + $class;
  $on: #{$self}--on;
  $off: #{$self}--off;
  // SWITCH VARIABLES
  $active-color: $color;
  $switch-size: $size;
  $ball-spacing: $spacing;
  $stretch-factor: $stretch;
  $ball-size: $switch-size - ($ball-spacing * 2);
  $ball-size-mobile: ($switch-size - 8) - ($ball-spacing * 2);
  $ball-slide-size: ($switch-size * ($stretch-factor - 1) + $ball-spacing);
  $ball-slide-size-mobile: (($switch-size - 15) * ($stretch-factor - 1) + $ball-spacing);
  // SWITCH STYLES
  height: $switch-size;
  width: $switch-size * $stretch-factor;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
  @media #{$media-sm-max} {
    height: $switch-size - 8;
    width: ($switch-size - 12) * $stretch-factor;
  }

  &#{$on},
  &#{$off} {
    &::before,
    &::after {
      content: '';
      left: 0;
      position: absolute !important;
    }
    &::before {
      height: inherit;
      width: inherit;
      border-radius: 6px;
      will-change: all;
      transition: all 0.3s ease-out;
      border-width: 1px;
      border-style: solid;
      background: white;
      border-color: $active-color !important;
    }
    &::after {
      top: $ball-spacing;
      height: $ball-size;
      width: $ball-size;
      border-radius: 4px;
      will-change: all;
      transition: all 0.4s ease-out;
      @media #{$media-sm-max} {
        height: $ball-size-mobile;
        width: $ball-size-mobile;
      }
    }
  }
  &#{$on} {
    &::before {
      border-color: $active-color !important;
    }
    &::after {
      background: $active-color !important;
      transform: translateX($ball-slide-size);
      @media #{$media-sm-max} {
        transform: translateX($ball-slide-size-mobile);
      }
    }
    .Switch-text {
      color: $active-color !important;
      transform: translateX($ball-spacing);
    }
  }
  &#{$off} {
    &::before {
      border-color: $inactive-color !important;
    }
    &::after {
      background: $inactive-color !important;
      transform: translateX($ball-spacing);
    }
    .Switch-text {
      color: $inactive-color !important;
      transform: translateX(50px);
      @media #{$media-sm-max} {
        transform: translateX(30px);
      }
    }
  }
}

@function get-switch-class($selector) {
  // First parse the selector using `selector-parse`
  // Extract the first selector in the first list using `nth` twice
  // Extract the first simple selector using `simple-selectors` and `nth`
  // Extract the class name using `str-slice`
  @return str-slice(nth(simple-selectors(nth(nth(selector-parse($selector), 1), 1)), 1), 2);
}

.Switch {
  position: relative;
  $self: &;
  $toggle: #{$self}-toggle;
  $class: get-switch-class($toggle);
  // default theme
  &#{$self}--default > #{$toggle} {
    // Always pass the $class to the mixin
    @include switch($class: $class);
  }

  #{$self}-text {
    display: flex;
    height: 100%;
    padding: 10px;
    align-items: center;
    position: absolute;
    text-align: left;
    font-weight: bold;
    font-size: 0.75em;
    text-transform: uppercase;
    transition: all 0.3s ease;
    @media #{$media-sm-max} {
      padding: 7px;
      font-size: 0.625em;
    }
  }
  // graphite-small theme
  &#{$self}--graphite-small > #{$toggle} {
    // A smaller switch with a `gray` active color
    // Always pass the $class to the mixin
    @include switch($color: gray, $size: 38px, $class: $class);
  }

  &#{$self}--disabled > #{$toggle} {
    &::after {
      display: none !important;
    }
    cursor: default !important;
    // Always pass the $class to the mixin
  }
}

.Switch.Switch--default > .Switch-toggle.Switch-toggle--disabled {
  cursor: default !important;
}
