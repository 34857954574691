.u-borderTop {
  border-top: solid 1px $colors-border;
}

.u-borderBottom {
  border-bottom: solid 1px $colors-border;
}

.u-borderBottom-blue {
  border-bottom: solid 1px $colors-blue-light;
}

.u-borderLeft {
  border-left: solid 1px $colors-border;
}

.u-borderRight {
  border-right: solid 1px $colors-border;
}

.u-borderLink {
  border-right: solid 1px $colors-border;
}

.u-border {
  border: solid 1px $colors-border;
}

.u-borderRadius {
  border-radius: $border-radius !important;
}

.u-borderBottomNone {
  border-bottom: solid 0px $colors-border;
}

.u-borderRadiusMedium {
  border-radius: 16px;
  @media #{$media-md-max} {
    border-radius: $border-radius-bigm !important;
  }
}

.u-borderRadiusBig {
  border-radius: $border-radius-bigx !important;
}

.u-noBorders {
  border: none;
}

@media #{$media-sm-max} {
  .u-sm-border--none {
    border-width: 0;
    border-radius: 0;
    &:hover {
      background: transparent;
    }
  }

  .u-sm-borderBottom {
    border-bottom: solid 1px $colors-border;
  }
}

@media #{$media-lg-viewport} {
  .u-lg-borderLeft {
    border-left: solid 1px $colors-border;
  }

  .u-lg-borderBottom {
    border-bottom: solid 1px $colors-border;
  }
}

.u-borderRadiusExtraBig {
  border-radius: 20px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  @media #{$media-md-max} {
    border-radius: $border-radius-bigm !important;
  }
}

.u-borderDottedRight {
  border-right: dotted 1px darken($colors-border, 20%);
}

@media #{$media-lg-viewport} {
  .u-lg-borderLeft {
    border-left: solid 1px $colors-border;
  }

  .u-lg-borderRadiusBig {
    border-radius: $border-radius-big !important;
  }

  .u-lg-borderBottom {
    border-bottom: solid 1px $colors-border;
  }
}

@media #{$media-md-max} {
  .u-md-max-borderLeft {
    border-left: solid 1px $colors-border;
  }

  .u-md-border-none {
    border-width: 0;
    border-radius: 0;
  }

  .u-md-max-borderBottom {
    border-bottom: solid 1px $colors-border;
  }
}

@media #{$media-md-viewport} {
  .u-md-noBorders {
    border: none;
  }
}

@media #{$media-sm-viewport} {
  .u-sm-noBorders {
    border: none;
  }

  .u-sm-borderBottom {
    border-bottom: solid 1px $colors-border;
    border-radius: 0;
  }
}
