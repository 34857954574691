.Step {
  border-top: 4px solid transparent;
  color: $colors-gray-lighter;
  &--active {
    border-top: 4px solid $colors-primary;
    color: $bs-black;
  }

  &--activeOwner {
    border-top: 4px solid $colors-owners;
    color: $bs-black;
  }
}
