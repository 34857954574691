/** @define Label; use strict */
/** @define Label; use strict */

.Label {
  padding: 3px 0.6em;
  display: inline-block;
  font-weight: normal;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: $border-radius;
  &--smaller {
    font-size: 0.65em;
    line-height: 1;
    padding: 0.5em 1em;
    border-radius: $border-radius-small;
  }
}

.Label--medium {
  min-width: 90px;
}

.Label--default {
  background-color: $colors-gray-light;
}

.Label--primary {
  background-color: $colors-primary;
}

.Label--secondary {
  background-color: $colors-secondary;
  color: $bs-black;
}

.Label--success,
.Label--flexible {
  background-color: $colors-success;
}

.Label--danger,
.Label--strict {
  background-color: $colors-danger;
  color: #fff;
}

.Label--info,
.Label--moderate {
  background-color: $colors-info;
  color: $bs-black;
  font-weight: 600;
}

.Label--pending {
  background-color: $colors-pending;
  color: $bs-black;
}

.Label--incomplete {
  background-color: $colors-gray-lighter;
  color: $bs-black;
}

.Label--void {
  background-color: #444;
}

.Label--greenLight {
  background-color: #2a8401;
  color: #ffffff;
}

.Label--gray {
  background-color: #f2f2f2;
  color: #17233c;
}

.Label--grayLight {
  background-color: #5e696a;
  color: #ffffff;
}

.Label--mute {
  background-color: #d5dae1;
  color: #17233c;
}

.Label--secondaryOutline {
  border: 1px solid $colors-secondary;
  color: $colors-secondary;
}

.Label--primaryOutline {
  border: 1px solid $colors-primary;
  color: $colors-primary;
}

.Label--voidOutline {
  background-color: #444;
}

.Label--small {
  padding: 0.6em 1em 0.5em;
  border-radius: $border-radius-small;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 1;
}

.Label--rounded {
  padding: 6px 0.6em;
  border-radius: $border-radius-rounded;
}

.Label--credit {
  background-color: $colors-success;
}

.Label--pending {
  background-color: $colors-pending;
}

.Label--paid {
  background-color: $colors-primary;
}

.Label--warning {
  background: #f4c064;
  color: $midnight;
}

.Label--sm {
  padding: 0 0.3em;
  font-size: 0.625em;
  width: 32px;
  padding: 0.22em 0.3em;
  border-radius: 3px;
  font-weight: bold;
}

.Label--md {
  padding: 0 0.3em;
  font-size: 14px;
  height: 24px;
  line-height: 19px;
  padding: 0.22em 0.3em;
  border-radius: 6px;
  font-weight: bold;
  align-items: center;
  align-items: center;
}

.Label--owners {
  background-color: $sun;
  color: $midnight;
}

.Label--seaFoam {
  background-color: $seafoam;
}

.Label--captains {
  background-color: $oceanic-dark;
}

@media only screen and (min-width: 1140px) and (max-width: 1250px) {
  .Label-midSize {
    font-size: 0.65em;
  }
}
