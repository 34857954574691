$popmenu-search-height: 50px;
$animation-transition-time: 0.2s;
$z-index-popmenu-elements: 1;
$z-index-popmenu-sort-menu: 2;

.PopMenu {
  height: $popmenu-search-height;
  box-sizing: border-box;
  position: relative;
  transition: box-shadow #{$animation-transition-time} ease-out, background-color #{$animation-transition-time} ease-out;
  transition-delay: #{$animation-transition-time};
  padding: 0;
  background-color: transparent;
  flex: 0 0 10%;
  position: absolute;
  top: -8px;
  right: 0;
}

.PopMenu--small {
  top: 3px;
  right: 3px;
  z-index: 99;
}

.PopMenuWrapper {
  border-radius: 50%;
  padding: 8px;
  -webkit-flex: none;
  flex: none;
  height: 24px;
  position: relative;
  transition: background-color #{$animation-transition-time} ease;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PopMenuWrapper--small {
  padding: 1px;
  height: 24px;
  background: rgba(255, 255, 255, 0.7);
  display: block;
}

.PopMenuMenu {
  right: 4px;
  text-align: left;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 0px;
  top: 44px;
  max-width: 340px;
  min-width: 180px;
  overflow: hidden;
  position: absolute;
  z-index: $z-index-popmenu-sort-menu;
  transform-origin: right top 0px;
  transform: scale(0.3);
  opacity: 0;
  visibility: hidden;
  transition: all #{$animation-transition-time} ease-in-out;

  li {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    transition: all #{$animation-transition-time} ease-in-out;
  }
}

.PopMenu--open {
  .PopMenuMenu {
    transform-origin: right top 0px;
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    transition: all #{$animation-transition-time} ease-in-out;

    li {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all #{$animation-transition-time} ease-in-out;
    }
  }
}

.PopMenuItem {
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  color: $colors-gray-dark;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  line-height: 12px;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  width: 100%;
  align-items: center;
  max-height: 34px;
  &:hover {
    background-color: $colors-gray-lighter;
  }
}

.dragging-helper-class-review {
  max-width: 185px;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.44);
  border-radius: 16px;
  .Panel {
    background-color: $sun;
    padding: 6px;
  }

  @media #{$media-sm-max} {
    max-width: 100%;
  }
}

.dragging-helper-class {
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.44);
  border-radius: 16px;
  max-width: 315px;

  .Panel {
    background-color: $sun;
  }

  @media #{$media-sm-max} {
    max-width: 100%;
  }
}
