/**
 * Element will be centered to its nearest relatively-positioned
 * ancestor.
 */



.u-bottom-0 {
  bottom: 0;
}

.u-left-0 {
  left: 0;
}


@media #{$media-md-max} {
  .u-md-posAbsoluteBottom {
    position: absolute;
    bottom: 0 !important;
  }
}

