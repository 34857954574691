@media #{$media-lg-min} {
  .Wrapper {
    width: 100%;
    overflow-x: hidden;
    &.filterbaroverflow {
      position: sticky;
      top: 60px;
      overflow: visible;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      z-index: 7;
    }
  }
  .searchPage {
    margin-top: 60px;
    .Wrapper {
      z-index: 8;
    }
  }
  .mapVisible > * {
    padding-right: 2em;
  }

  .mapNotVisible > * {
    // padding-right: 0;
    min-height: 50vh;
  }
}

@media #{$media-sm-max} {
  .Wrapper {
    width: 100%;
    overflow-x: hidden;
  }
  .searchPage {
    @supports (-webkit-touch-callout: none) {
      margin-top: 54px;
      height: 100%;
    }

    .Wrapper {
      z-index: 8;
      &.filterbaroverflow {
        overflow-x: visible;
        // position: fixed;
        margin-top: 54px;
        border-bottom-width: 0 !important;
        padding-bottom: 0 !important;
        width: 100%;
        padding-left: 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding-right: 0;
        -webkit-overflow-scrolling: touch;
        z-index: 7;
      }
    }

    &.mapVisiblePage {
      margin-top: 0;
      .Wrapper.filterbaroverflow {
        border-bottom-width: 0;
        background: linear-gradient(180deg, #7aaedd 16.65%, rgba(0, 0, 0, 0) 94.03%);
        top: 0px;
        box-shadow: none;
        position: fixed;
      }
    }

    &.mapNotVisiblePage {
      // &.headerInView {
      //   .Wrapper.filterbaroverflow {
      //     position: sticky;
      //     top: 54px;
      //     transition: top 0.25s ease-in-out;
      //     z-index: 17;
      //   }
      // }
      // &.headerNotInView {
      //   .Wrapper.filterbaroverflow {
      //     top: 0px;
      //     position: fixed;
      //     // transition: top 0.38s ease-in-out;
      //     z-index: 17;
      //   }
      // }
    }
  }
}

@media #{$media-md-viewport} {
  .Wrapper {
    width: 100%;
    overflow-x: hidden;
  }
  .searchPage {
    margin-top: 60px;
    .Wrapper {
      z-index: 8;

      &.filterbaroverflow {
        overflow-x: visible;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        -webkit-overflow-scrolling: touch;
        z-index: 7;
      }
    }
  }
  .mapVisible > * {
    padding-right: 3em;
  }

  .mapNotVisible > * {
    // padding-right: 0;
  }
}
