/** @define Container; use strict */

.Container {
  margin: 0 auto;
  max-width: $viewport-min-xl;
  padding: 0 1em;
}

.Container--search {
  padding: 0 0 0 2em !important;
}

.Container--full {
  max-width: none;
}

.Container--xxl {
  width: 100%;
  max-width: $viewport-max-xxl;
}

.Container--full {
  width: 100%;
  max-width: auto;
}

.Container--xl {
  max-width: $viewport-max-xl;
}

.Container--md {
  max-width: $viewport-max-md;
}

.Container--sm {
  max-width: $viewport-max-sm;
}

.Container--smx {
  max-width: 490px;
}

.Container--xs {
  max-width: $viewport-max-xs;
}

@media #{$media-md-viewport} {
  .Container {
    padding: 0 2.6em;
  }
}

@media #{$media-sm-viewport} {
  .Container {
    padding: 0 1.656em;
  }

  .Container--xxl {
    padding: 0 1.656em;
  }
}

@media #{$ipad-pro-portrait-breakpoint} {
  .Container {
    width: 100%;
    max-width: 720px;
  }

  .Container > .Grid > [class*='u-lg-size']:first-child {
    margin-bottom: 5em;
  }
}

@media #{$ipad-pro-landscape-breakpoint} {
  .Container {
    width: 100%;
    max-width: 1200px;
  }
}
