/**
 * Offset: breakpoint 1 (small)
 *
 * Specify the proportional offset after an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 5, 6, 8, 10, 12 section
 */

@media #{$media-sm-viewport} {
  .u-sm-after2of4 {
    margin-right: 50% !important;
  }
}
