.u-display6 {
  @include fontSize64;
  margin-bottom: 0;
}

.u-display5 {
  @include fontSize56;
  margin-bottom: 0;
}

.u-display4 {
  @include fontSize48;
  margin-bottom: 0;
}

.u-display3 {
  @include fontSize40;
  margin-bottom: 0;
}

.u-display2 {
  @include fontSize32;
  margin-bottom: 0;
}

.u-display1 {
  @include fontSize24;
  margin-bottom: 0;
}

.u-display075 {
  @include fontSize21;
  margin-bottom: 0;
}

.u-display05 {
  @include fontSize20;
  margin-bottom: 0;
}

.u-display06 {
  @include fontSize22;
  margin-bottom: 0;
}

@media #{$media-lg-viewport} {
  .u-lg-display4 {
    @include fontSize48;
    margin-bottom: 0;
  }

  .u-lg-display3 {
    @include fontSize40;
    margin-bottom: 0;
  }

  .u-lg-display2 {
    @include fontSize32;
    margin-bottom: 0;
  }

  .u-lg-display1 {
    @include fontSize24;
    margin-bottom: 0;
  }

  .u-md-display05 {
    @include fontSize20;
    margin-bottom: 0;
  }
}

@media #{$media-md-viewport} {
  .u-md-display4 {
    @include fontSize48;
    margin-bottom: 0;
  }

  .u-md-display3 {
    @include fontSize40;
    margin-bottom: 0;
  }

  .u-md-display2 {
    @include fontSize32;
    margin-bottom: 0;
  }

  .u-md-display1 {
    @include fontSize24;
    margin-bottom: 0;
  }

  .u-md-display05 {
    @include fontSize20;
    margin-bottom: 0;
  }
}

@media #{$media-sm-viewport} {
  .u-sm-display4 {
    @include fontSize32;
    margin-bottom: 0;
  }

  .u-sm-display3 {
    @include fontSize24;
    margin-bottom: 0;
  }

  .u-sm-display2 {
    @include fontSize20;
    margin-bottom: 0;
  }

  .u-sm-display1 {
    @include fontSize18;
    margin-bottom: 0;
  }

  .u-sm-display05 {
    @include fontSize12;
    margin-bottom: 0;
  }
}
