/* errors */

.ControlError {
  position: relative;
}

.ControlError-list {
  background-color: transparent;
  color: $colors-danger;
  &--absolute {
    position: absolute;
    top: 21px;
    left: 33px;
  }
}

/* when the error can't be attached directly to the
control
*/
.ControlError > .ControlError-list {
  display: none;
  margin-bottom: 0.5em;
  &:last-child {
    margin-bottom: 0;
  }
}

.ControlError.is-invalid > .ControlError-list {
  display: block;
}

.ControlError--tooltip.is-invalid {
  margin-top: 0;
}

.ControlError-listItem {
  font-size: 12px;
  padding: 0;
  line-height: 18px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &.ControlError-listItem--small {
    font-size: 11px;
    padding: 2px 0 2px 0;
  }
}

#ControlError-guestListWrapper {
  .ControlError-list {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -42px;
    .ControlError-listItem {
      font-size: 11px;
    }
  }
}
