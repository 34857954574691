#toggle_on_site:hover {
  cursor: pointer;
}

.switch {
  position: relative;
  display: block;
  display: flex;
  width: 100px;
  height: 32px;
  margin: 0 10px 0 0;
  border-radius: 6px;
  cursor: pointer;
}

.switch-input {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  flex: 1;
  font-size: 10px;
  text-transform: uppercase;
  border: 1px solid #cccccc;
  border-radius: inherit;
  transition: all 0.3s ease;
  &:before,
  &:after {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
  }
  &:before {
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }
  &:after {
    content: attr(data-on);
    left: 11px;
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }
}

.switch-input:checked ~ .switch-label {
  border: 1px solid $moss !important;
  &:before {
    opacity: 0;
  }
  &:after {
    opacity: 1;
  }
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  background: #ccc;
  border-radius: 6px;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
  }
}

.switch-input:checked ~ .switch-handle {
  left: 70px;
  background-color: $moss;
}

.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.switch-left-right {
  .switch-label {
    overflow: hidden;
    &:before,
    &:after {
      width: 20px;
      height: 31px;
      top: 4px;
      left: 0;
      right: 0;
      bottom: 0;
      text-indent: -12px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-weight: 600;
    }
    &:before {
      text-align: left;
      padding-left: 55px;
    }
    &:after {
      text-align: left;
      text-indent: 9px;
      color: $moss;
      left: -100px;
      opacity: 1;
      width: 100%;
    }
  }
  .switch-input:checked ~ .switch-label {
    &:before {
      opacity: 1;
      left: 100px;
    }
    &:after {
      left: 0;
    }
    background: inherit;
  }
}
