/** @define Grid */

/**
 * Core grid component
 *
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */

/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child `Grid-cell` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.Grid {
  box-sizing: border-box;
  display: flex; /* 1 */
  flex-flow: row wrap;
  margin: 0; /* 1 */
  padding: 0; /* 1 */
}

/**
 * Modifier: center align all grid cells
 */

.Grid--alignCenter {
  justify-content: center;
}

/**
 * Modifier: right align all grid cells
 */

.Grid--alignRight {
  justify-content: flex-end;
}

/**
 * Modifier: middle-align grid cells
 */

.Grid--alignMiddle {
  align-items: center;
}

/**
 * Modifier: bottom-align grid cells
 */

.Grid--alignBottom {
  align-items: flex-end;
}

/**
 * Modifier: allow cells to equal distribute width
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex - http://git.io/vllC7
 *    Use `0%` to avoid bug in IE10/11 with unitless flex basis - http://git.io/vllWx
 */

.Grid--fit > .Grid-cell {
  flex: 1 1 0%; /* 1 */
}

/**
 * Modifier: all cells match height of tallest cell in a row
 */

.Grid--equalHeight > .Grid-cell {
  display: flex;
}

/**
 * Modifier: gutters
 */

.Grid--withGutter {
  margin: 0 calc(-0.5 * #{$grid-gutter-size});
}

.Grid--withGutter > .Grid-cell {
  padding: 0 calc(0.5 * #{$grid-gutter-size});
}

.Grid--withGutterWide {
  margin: 0 calc(-1 * #{$grid-gutter-size});
}

.Grid--withGutterWide > .Grid-cell {
  padding: 0 calc(1 * #{$grid-gutter-size});
}

.Grid--withGutterExtraWide {
  margin: 0 calc(-1.5 * #{$grid-gutter-size});
}

.Grid--withGutterExtraWide > .Grid-cell {
  padding: 0 calc(1.5 * #{$grid-gutter-size});
}

.Grid--withGutterSmall {
  margin: 0 calc(-0.25 * #{$grid-gutter-size});
}

.Grid--withGutterSmall > .Grid-cell {
  padding: 0 calc(0.25 * #{$grid-gutter-size});
}

@media #{$media-sm-viewport} {
  .Grid-sm---withGutterTiny {
    margin: 0 calc(-0.15 * #{$grid-gutter-size}) !important;
  }
  .Grid-sm---withGutterTiny > .Grid-cell {
    padding: 0 calc(0.15 * #{$grid-gutter-size}) !important;
  }

  .Grid--withGutterWide {
    margin: 0 calc(-1 * 26px);
  }

  .Grid--withGutterWide > .Grid-cell {
    padding: 0 calc(1 * 25px);
  }

  .Grid-sm---withGutterTiny > .Grid-cell-sm--padding {
    padding: 0;
  }

  .Grid--withGutterWide > .Grid-cell-sm--padding {
    padding: 0;
  }

  .Grid-sm--withGutterWideNoMargin {
    margin: 0;
  }

  .Grid--withGutterWide > .Grid-cell-sm--noGutter {
    padding: 0;
  }

  .Grid-cell {
    &.Grid-cell-sm--extraGutter {
      padding: 0 calc(1.5 * #{$grid-gutter-size});
    }
  }

  .Grid-sm--noPadding > .Grid-cell {
    padding: 0 !important;
  }
}
/* Grid cell
   ========================================================================== */

/**
 * No explicit width by default. Rely on combining `Grid-cell` with a dimension
 * utility or a component class that extends 'Grid'.
 *
 * 1. Set flex items to full width by default
 */

.Grid-cell {
  box-sizing: inherit;
  flex: 0 0 100%; /* 1 */
}

/**
 * Modifier: horizontally center one unit
 * Set a specific unit to be horizontally centered. Doesn't affect
 * any other units. Can still contain a child `Grid` object.
 */

.Grid-cell--center {
  margin: 0 auto;
}

.Grid-cell--midBackground {
  position: relative;
  &:before {
    white-space: nowrap;
    box-sizing: border-box;
    content: ' ';
    position: absolute;
    background: #fff;
    left: 0px;
    top: -100%;
    width: 200%;
    height: 500%;
    @media #{$media-md-max} {
      top: 0;
      width: 100%;
    }
    @media #{$ipad-pro-portrait-breakpoint} {
      top: 0;
      width: 100%;
    }
  }
}
.h-100 {
  min-height: 100%;
  @media #{$media-lg-min} {
    min-height: 90vh;
  }
}
.w-100 {
  width: 100%;
}

.Grid-spacing {
  padding: 0 calc(0.5 * #{$grid-gutter-size});
}

.Grid-spacingLeft {
  margin-left: calc(0.5 * #{$grid-gutter-size});
}

.Grid-spacingLeftSmall {
  margin-left: calc(0.5 * 16px);
}

.Grid-spacingRight {
  margin-right: calc(0.5 * 18px);
}

@media #{$media-sm-max} {
  .Grid-sm-spacing {
    padding: 0 calc(0.5 * #{$grid-gutter-size});
  }
}
