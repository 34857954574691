.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.pac-logo:after {
  content: '';
  padding: 1px 1px 1px 0;
  height: 18px;
  box-sizing: border-box;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}
.hdpi.pac-logo:after {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}
.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  font-size: 11px;
  color: #999;
  width: 100%;

  img {
    margin-right: 13px;

    &.current {
      margin-right: 10px;
    }
  }
}
.no-border {
  border-top: none;
}
.pac-item-flex {
  display: flex;
}
.pac-item:hover {
  background-color: #fafafa;
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}
.pac-matched {
  font-weight: 700;
}
.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}
.pac-item-main {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-right: 3px;
  // line-height: 51px;
  color: #17233c;
}
.pac-item-sub {
  margin-top: 1.5px;
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}
.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}
.pac-icon-search {
  background-position: -1px -1px;
}
.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}
.pac-icon-marker {
  background-position: -1px -161px;
}
.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}
.pac-placeholder {
  color: gray;
}
.pac-target-input:-webkit-autofill {
  animation-name: beginBrowserAutofill;
}
.pac-target-input:not(:-webkit-autofill) {
  animation-name: endBrowserAutofill;
}

.pac-container {
  z-index: 9999 !important;
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 100%;
}

.pac-item {
  font-size: 0.875em;
  font-family: 'AvenirNext', 'Avenir', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 6px 1em;
}
.pac-item-query {
  font-size: 14px;
}

.pac-item-main {
  font-size: 16px;
}

.pac-matched {
  font-weight: 600;
}

