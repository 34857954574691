.LoadingSpinner {
  animation: rotator 1.2s linear infinite;
  stroke: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -17px;
  margin-left: -17px;
}

.LoadingSpinner-circle {
  stroke-dashoffset: 0;
  transform-origin: center;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.WidgetLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.WidgetLoader-item {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}

.WidgetLoader-item-enter {
  opacity: 0.01;
  background: rgba(255, 255, 255, 0.01);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.WidgetLoader-item-enter-active {
  opacity: 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 99;
  transition: all 300ms ease-in-out;
}

.WidgetLoader-item-exit {
  opacity: 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 99;
}

.WidgetLoader-item-exit-active {
  opacity: 0.01;
  background: rgba(255, 255, 255, 0.01);
  transition: all 300ms ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.WidgetLoader-item-exit-done {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background: rgba(255, 255, 255, 0.8);
}

.WidgetLoader-item-enter-done {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background: rgba(255, 255, 255, 0.8);
}

//loading spinner v2
.LoadingSpinner--large {
  position: relative;
  width: 60px;
  height: 60px;
  &.animate {
    animation: rotate 2s linear infinite;
    z-index: 2;
  }
}

.LoadingSpinner--medium {
  position: relative;
  width: 54px;
  height: 54px;
  &.animate {
    animation: rotate 2s linear infinite;
    z-index: 2;
  }
}

.LoadingSpinner--small {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 50%;
  left: 50%;
  margin-top: -17px;
  margin-left: -17px;
  &.animate {
    animation: rotate 2s linear infinite;
    z-index: 2;
  }
}

.LoadingSpinner-circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  transform: translate(5px, 5px);

  &.large {
    stroke-width: 6;
    stroke-dasharray: 140;
    stroke-dashoffset: 140;
    &.animate {
      animation: animateLarge 1.8s linear infinite;
    }
  }

  &.medium {
    stroke-width: 6;
    stroke-dasharray: 125;
    stroke-dashoffset: 125;
    &.animate {
      animation: animateMedium 1.8s linear infinite;
    }
  }

  &.small {
    stroke-width: 4;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    &.animate {
      animation: animateSmall 1.2s linear infinite;
    }
  }
}

.LoadingSpinner-bg {
  stroke: #f4f5f6 !important;
  stroke-dasharray: 0 !important;
  stroke-dashoffset: 0 !important;
}
.LoadingSpinner-bgWrapper {
  position: absolute !important;
  left: 0;
}

@keyframes animateLarge {
  0%,
  100% {
    stroke-dashoffset: 140;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 280;
  }
}

@keyframes animateMedium {
  0%,
  100% {
    stroke-dashoffset: 125;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 250;
  }
}

@keyframes animateSmall {
  0%,
  100% {
    stroke-dashoffset: 80;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 160;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
