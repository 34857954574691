$input-radio-width-height: 22px;
$input-radio-indicator-width-height: 8px;

input[type='radio'] {
  width: $input-radio-width-height;
  height: $input-radio-width-height;
  border: 1px solid #cccccc;
  border-radius: 100%;
  appearance: none;
  background-color: white;
  position: relative;
  outline: none;
  transition: all ease-in-out 0.2s;
  &.Radio--owners {
    background-color: $stone;
    border: 1px solid $mid-rock;
  }

  &.Radio--ownersSecondary {
    background-color: $stone;
    border: 1px solid $mid-rock;
  }
}

input[type='radio']:checked {
  width: $input-radio-width-height;
  height: $input-radio-width-height;
  border: 1px solid $colors-primary;
  border-radius: 100%;
  appearance: none;
  background-color: $colors-primary;
  position: relative;
  outline: none;
  transition: all ease-in-out 0.2s;
  position: relative;

  &.Radio--secondary {
    background-color: #fff;
  }

  &.Radio--owners {
    background-color: $sea-weed;
    border: 1px solid $sun;
    &:disabled {
      border: 1px solid $mid-rock;
      background-color: $stone;
    }
  }

  &.Radio--ownersSecondary {
    background-color: white;
    border: 1px solid $sea-weed;
  }
}

input[type='radio']::before,
input[type='radio']::after {
  margin: auto;
  content: '';
  display: block;
  position: absolute;
}

input[type='radio']::after {
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: $input-radio-indicator-width-height;
  height: $input-radio-indicator-width-height;
  background-color: #fff;
  transform: scale(0.5);
  transition: all ease-in-out 0.2s;
  border-radius: 100%;

  &.Radio--secondary {
    background-color: $colors-primary;
  }

  &.Radio--owners {
    background-color: $sun;
    &:disabled {
      background-color: $mid-rock;
    }
  }

  &.Radio--ownersSecondary {
    background-color: $sea-weed;
  }
}

input[type='radio']:checked::after {
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: $input-radio-indicator-width-height;
  height: $input-radio-indicator-width-height;
  background-color: #fff;
  transform: scale(1);
  transition: all ease-in-out 0.2s;
  border-radius: 100%;
  opacity: 1;
  transform: scale(1);
}

input[type='radio'].Radio--secondary:checked::after {
  background-color: $colors-primary;
}

input[type='radio'].Radio--owners:checked::after {
  background-color: $sun;
}

input[type='radio'].Radio--owners:disabled::after {
  background-color: $mid-rock;
}

input[type='radio'].Radio--ownersSecondary:checked::after {
  background-color: $sea-weed;
}
