/** @define UserPic; use strict */
$mobileViewBreakpoint: 710px;
$bigScreenBreakpoint: 1440px;

.CheckoutContainer {
  position: relative;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    padding: 0 22px;
  }
}

.CheckoutWrapper {
  display: flex;
  max-width: 1127px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;

  @media only screen and (max-width: $bigScreenBreakpoint) {
    max-width: 992px;
  }

  @media only screen and (max-width: 375px) {
    max-width: 331px;
  }

  @media only screen and (max-width: $mobileViewBreakpoint) {
    flex-direction: column;
    max-width: 100%;
  }
}

.PaymentWrapper {
  width: 100%;
  max-width: 547px;
  margin-right: 69px;

  @media only screen and (max-width: $bigScreenBreakpoint) {
    max-width: 526px;
  }

  @media only screen and (max-width: 800px) {
    margin-right: 25px;
  }

  @media only screen and (max-width: $mobileViewBreakpoint) {
    margin-right: 0px;
    max-width: 100%;
  }
}

.BookingWrapper {
  width: 100%;
  max-width: 327px;
  margin-left: 69px;

  @media only screen and (max-width: 800px) {
    margin-left: 25px;
  }

  @media only screen and (max-width: $mobileViewBreakpoint) {
    margin-left: 0px;
    max-width: unset;
  }
}

.BookingWrapperNotModal {
  @media only screen and (max-width: $mobileViewBreakpoint) {
    display: none;
  }
}




.OptionalText {
  font-size: 16px;
  line-height: 24px;
  color: #5e696a;
  font-weight: 300;
}
.CheckoutDataWrapper {
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $mobileViewBreakpoint) {
    padding: 30px 0;
  }
}

.CheckoutDataItemRow {
  display: flex;
  justify-content: space-between;
}

.Bold {
  font-weight: 600;
}




.CheckBoxError {
  input[type='checkbox'] {
    border: 1px solid #bc350a;
  }
}

.withBottomMargin {
  margin-bottom: 28px;
}

.InputRow {
  display: flex;
  justify-content: space-between;
}

.InputFieldInRow {
  width: 30%;
}

.CollapsibleTrigger {
  padding-top: 24px;
  padding-bottom: 24px;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.3px;
  color: #000000;
  cursor: pointer;
  position: relative;
  padding-right: 35px;

  &::after {
    transform: rotate(45deg);
    content: '';
    height: 9px;
    width: 9px;
    border-bottom: 2px solid #0751c2;
    border-right: 2px solid #0751c2;
    position: absolute;
    right: 10px;
    top: 25px;
    display: block;
  }

  &.opened {
    // padding-bottom: 5px;
  }

  &.opened::after {
    transform: rotate(225deg);
    right: 10px;
    top: 29px;
  }
}

.CollapsibleContent {
  // margin-top: 12px;
  // padding-right: 40px;
}

.PromoCodeHeading {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #17233c;
}

.PromoCodeWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

.PromoCodeInputWrapper {
  max-width: 344px;
  width: 100%;
  margin-right: 20px;
}

.PromoCodeButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0751c2;
  cursor: pointer;
}

.PromoCodeButtonWithMargin {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0751c2;
  cursor: pointer;
  margin-bottom: 16px;
}

.PromoCodeApplied {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 12px;
  color: #2a8500;
  margin-top: 4px;
  position: absolute;
}

.PromoCodeSpaceDiv {
  padding-top: 16px;
}

.CheckoutSubmitButton {
  display: flex;
  align-items: center;
  line-height: 18px;
}


.CheckoutSubmitButtonImage {
  margin-right: 16px;
  width: 16px;
}


.PartnershipWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.PartnershipHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #4b5765;
  margin-bottom: 12px;
}

.PartnershipLogoWrapper {
  display: flex;
}

.GeicoLogo {
  margin-right: 30px;
  width: 90px;
}

.BoatUsLogo {
  width: 112px;
}


.CreditCardSelectWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  &:first-child {
    padding: 0px;
  }
}

.CreditCardSelect {
  z-index: 9999;
}

.AddNewCreditCardWrapper {
  display: flex;
  align-items: center;
  margin-top: 25px;
  cursor: pointer;
}

.AddCardSafeMargin {
  margin-top: 45px !important;
}

.AddNewCreditCardImage {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.AddNewCreditCardText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0751c2;
}



