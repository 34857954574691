/* grid and arrange gutter setting */
$grid-gutter-size: 25px;
$grid-gutter-size-small: 6px;

/* min/max viewport widths */
$viewport-min-xs: 300px;
$viewport-max-xs: 375px;

$viewport-min-sm: 320px;
$viewport-max-sm: 640px;

$viewport-max-sm-wide: 720px;

$viewport-min-md: 640px;
$viewport-max-md: 960px;

$viewport-min-lg: 960px;
$viewport-max-lg: 1140px;

$viewport-min-xl: 1140px;
$viewport-max-xl: 1360px;

$viewport-min-xxl: 1200px;
$viewport-max-xxl: 1400px;

$viewport-max-hd: 1700px;

/* min/max viewport heights */

$viewport-max-h-xs: 576px;
$viewport-max-h-sm: 680px;

/* media breakpoints */
$media-sm-viewport: 'only screen and (min-width:#{$viewport-min-sm}) and (max-width:#{$viewport-max-sm})';
$media-md-viewport: 'only screen and (min-width:#{$viewport-min-md}) and (max-width:#{$viewport-max-md})';
$media-desktop-viewport: 'only screen and (min-width:#{$viewport-max-sm}) and (max-width:#{$viewport-min-xl})';
$media-lg-viewport: 'only screen and (min-width:#{$viewport-min-lg})';
$media-size-lg-viewport: 'only screen and (min-width:#{$viewport-min-lg}) and (max-width:#{$viewport-max-lg})';
$media-size-lg-desktop-viewport: 'only screen and (min-width:#{$viewport-min-lg}) and (max-width: 1355px)';

$media-xs-min: 'only screen and (min-width:#{$viewport-min-xs})';
$media-xs-max: 'only screen and (max-width:#{$viewport-max-xs})';

$media-sm-min: 'only screen and (min-width:#{$viewport-min-sm})';
$media-sm-max: 'only screen and (max-width:#{$viewport-max-sm})';

$media-sm-wide-max: 'only screen and (max-width:#{$viewport-max-sm-wide})';

$media-md-min: 'only screen and (min-width:#{$viewport-min-md})';
$media-md-max: 'only screen and (max-width:#{$viewport-max-md})';

$media-lg-min: 'only screen and (min-width:#{$viewport-min-lg})';
$media-lg-max: 'only screen and (max-width:#{$viewport-max-lg})';

$media-xl-min: 'only screen and (max-width:#{$viewport-min-xl})';
$media-xl-max: 'only screen and (max-width:#{$viewport-max-xl})';

$media-hd-max: 'only screen and (max-width:#{$viewport-max-hd})';

/* media breakpoints height */

$media-sm-h-viewport: 'only screen and (max-height:#{$viewport-max-h-sm})';
$media-xs-h-viewport: 'only screen and (max-height:#{$viewport-max-h-xs})';

$ipad-pro-portrait-breakpoint: '(min-device-width : 1024px) and (max-device-width : 1024px) and (min-device-height : 1366px) and (max-device-height : 1366px) and (min-width: 1024px) and (max-width: 1024px)';
$ipad-pro-landscape-breakpoint: '(min-device-width : 1024px) and (max-device-width : 1024px) and (min-device-height : 1366px) and (max-device-height : 1366px) and (min-width: 1366px) and (max-width: 1366px)';

/* border radius */
$border-radius: 6px;
$border-radius-small: 4px;
$border-radius-button: 6px;
$border-radius-big: 9px;
$border-radius-bigm: 10px;
$border-radius-bigx: 16px;
$border-radius-extrabig: 16px;
$border-radius-rounded: 20px;

/* z-indexes */
$z-index-hidden: -1;
$z-index-boatcard-content: 1;
$z-index-boat-photo-gallery-count: 9;
$z-index-delete-control: 9;
$z-index-package-picker: 9;
$z-index-zendesk-widget: 9;
$z-index-navtop: 11;
$z-index-top-message: 10;
$z-index-search-map: 10;
$z-index-sticky-payment-btn: 10;
$z-index-dropdown: 10;
$z-index-search-filter-buttons: 11;
$z-index-datepicker: 12;
$z-index-notification-bubble: 12;
$z-index-loading: 12;
$z-index-loading-spinner: 13;
$z-index-search-filters: 13;
$z-index-remodal-overlay: 13;
$z-index-remodal-wrapper: 14;
$z-index-google-places-autocomplete: 99;
$z-index-tooltipster: 16;
$z-index-nav-off-canvas-overlay: 16;
$z-index-nav-off-canvas-menu: 17;
$z-index-nav-off-canvas-trigger: 17;

.zEWidget-launcher {
  z-index: $z-index-zendesk-widget !important;
}

.u-noOutline:focus {
  outline: 0;
}

/* button and control sizing */

/* default */
@mixin controlSizeDefault {
  padding: 0.9em;
}

@mixin controlSelectSizeDefault {
  padding: 0.9em 0.75em 0.9em 0.75em;
}

@mixin controlSizeShort {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

@mixin controlSelectSizeShort {
  padding: 0.5em 2em 0.5em 0.75em;
}

@mixin controlSizeWide {
  padding-left: 1.25em;
  padding-right: 1.25em;
}

/* input text mixin */
@mixin inputText {
  -webkit-appearance: none;
  box-shadow: none;
  outline: none;
  line-height: normal;
  color: $midnight;
  background-color: #fff;
  &::placeholder {
    color: $colors-input-placeholder;
  }
}

/* html */
html {
  font-size: 100%;
  line-height: 1.4;
  background-color: #fff;
  color: $colors-gray-dark;
  font-family: 'AvenirNext', 'Avenir', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 100%;
  font-weight: 400;
}

.no-scroll {
  overflow: hidden;
  #__next {
    overflow: hidden;
  }
}

@media #{$media-sm-viewport} {
  body {
    margin: 0;
    width: 100%;
    overflow: hidden;
    height: 100vh;
  }
  html {
    overflow: hidden;
  }
}

/* headings */
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  @include fontSize48;
  margin-bottom: 0.2em; /* 8px */
  @media #{$media-md-max} {
    @include fontSize32;
  }
  margin-block-end: 24px;
  letter-spacing: -0.02em;
}
h2 {
  @include fontSize32;
  margin-bottom: 0.25em; /* 8px */
  @media #{$media-md-max} {
    @include fontSize24;
  }
}
h3 {
  @include fontSize26;
  margin-bottom: 0.333333em; /* 8px */
}
h4 {
  @include fontSize20;
  margin-bottom: 0.4em; /* 8px */
}
h5 {
  @include fontSize18;
  margin-bottom: 0.444444em; /* 8px */
}
h6 {
  font-weight: 800;
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 0.5em; /* 8px */
}

/* paragraph */
p {
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
}

/* links */
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  color: $colors-primary;
  text-decoration: none;
}
