/**
 * Display-type utilities
 */

.u-block {
  display: block !important;
}

.u-inline {
  display: inline !important;
}


.u-opacityShown {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

/**
 * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
 * inline-block will display at its default size, and not limit its width to
 * 100% of an ancestral container.
 */

.u-inlineBlock {
  display: inline-block !important;
  max-width: 100%; /* 1 */
}


