.isFramed {
  background: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.loadableFiller {
  min-height: calc(60vh - 60px);
}
