/** @define InputText; use strict */

input:-webkit-autofill::first-line {
  font-size: 16px;
  font-weight: 400;
  font-family: 'AvenirNext';
}

.InputText {
  @include controlSizeDefault;
  @include inputText;
  border: solid 1px $colors-border;
  border-radius: $border-radius;
  height: 50px;
  transition: all 200ms;
}

.InputText--withAnimatedLabel {
  font-weight: 600;
  font-size: 1em;
  padding-top: 32px;
  padding-left: 11px;
}

.InputText:disabled {
  cursor: not-allowed;
}

.InputText:focus {
  border: solid 1px $mid-rock;
}

.InputText.InputText--focus {
  border: solid 1px $mid-rock;
}

/* sizing */
.InputText--short {
  @include controlSizeShort;
}

.InputText:disabled {
  opacity: 0.5;
  background-color: rgb(242, 242, 242);
  border-color: rgb(230, 230, 230);
}

/* flair */

.InputText--withSuffix {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.InputText--withPrefix {
  border-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  :focus {
    border-left: 0 !important;
  }
}

.InputText--withSuffix.is-invalid {
  border-left: solid 1px $colors-danger;
  border-right: solid 0px $colors-danger;
}
.InputText--withPrefix.is-invalid {
  border-left: solid 0px $colors-danger;
  border-right: solid 1px $colors-danger;
}

.InputText.is-invalid {
  border-color: $colors-danger;
  color: $colors-danger;
}

.InputText.is-invalid ~ i:before {
  color: $colors-danger;
}

.InputText--withIcon {
  padding-left: 2.25em;
}

.InputText--withIconBig {
  padding-left: 42px;
}

.InputText-closeIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  cursor: pointer;
}

.InputText--borderLeft {
  width: 100%;
  border: 0;
  padding: 0 1em;
  align-self: stretch;
  border-left: 1px solid $colors-border-light;
  border-radius: 0 $border-radius $border-radius 0;
  outline: none;
}

.InputText--naked {
  border: 0;
  border-radius: 0;
  &:focus {
    border: 0;
  }
}

.InputText--transparent {
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
}

.InputText--big {
  padding: 1.822em 1em;
  height: 70px;
  @media #{$media-sm-max} {
    padding: 1.6em 1em;
    height: 64px;
  }
}

.InputText--big-fat {
  padding: 1.222em 1em;
  height: 70px;
  font-size: 20px;
  line-height: 34px;

  @media #{$media-sm-max} {
    font-size: 15px;
    line-height: 34px;
    height: 64px;
  }
}

.InputText--md {
  padding: 1.2em 1em;
  @media #{$media-sm-max} {
    padding: 1em 1em;
  }
}

.InputText--bigCorners {
  border-radius: 10px;
}

.InputText--dark {
  color: white;
  background: rgba(0, 0, 0, 0.16);
  border: solid 0px rgba(0, 0, 0, 0.16);
  &:focus {
    border: solid 0px $colors-border-dark;
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.46);
  }
}

input.InputText:-webkit-autofill,
input.InputText:-webkit-autofill:hover,
input.InputText:-webkit-autofill:active,
input.InputText:-webkit-autofill:focus,
input.InputText:-webkit-autofill::first-line {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input.InputText--withAnimatedLabel:-webkit-autofill,
input.InputText--withAnimatedLabel:-webkit-autofill:hover,
input.InputText--withAnimatedLabel:-webkit-autofill:active,
input.InputText--withAnimatedLabel:-webkit-autofill:focus,
input.InputText--withAnimatedLabel:-webkit-autofill::first-line {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
  font-weight: 600;
}

input.InputText--dark:-webkit-autofill,
input.InputText--dark:-webkit-autofill:hover,
input.InputText--dark:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.InputText--medium {
  padding: 0.8em 1.25em;
}

.InputText--mediumMd {
  padding: 0.8em 1.25em;
  padding-right: 1em;
  padding-left: 1.46em;
  height: 70px;
  @media #{$media-sm-max} {
    height: 65px;
    padding: 0.9em;
  }
}

#MessageOwnerWidget {
  .InputText--mediumMd {
    height: 65px;
    border-radius: 6px;
    margin-bottom: 1.25em !important;
    @media #{$media-sm-max} {
      height: 60px;
      margin-bottom: 0.625em !important;
      border-radius: 10px;
    }
  }
}

.InputText--large {
  padding: 0.967em 1.25em;
  height: 70px;
}

@media #{$media-sm-max} {
  .InputText-sm--large {
    height: 65px;
    padding: 0.9em;
  }
}

#MessageOwnerWidget {
  .InputText--large {
    height: 65px;
    margin-bottom: 1.25em !important;
    border-radius: 6px;
    @media #{$media-sm-max} {
      height: 60px;
      padding: 0 0.9em;
      margin-bottom: 0.625em !important;
      border-radius: 10px;
      .InputText-sm--large {
        height: 60px;
        padding: 0.8em 1.25em;
      }
    }
  }
}

.InputText--BookingCalendar {
  padding: 0em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-validation-bubble-message {
  display: none !important;
}

.InputText-label {
  transform: translate(12px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: $rock;
  transform-origin: top left;
}

.InputText-label--isFocused {
  transform: translate(12px, 5px) scale(0.75);
  color: $mid-rock;
}

.InputText-label--withIcon {
  transform: translate(42px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: $rock;
  transform-origin: top left;
}

.InputText-label--withIcon {
  &.InputText-label--isFocused {
    transform: translate(42px, 5px) scale(0.75);
    color: $mid-rock;
  }
}

.InputText-prefix {
  transform: translate(18px, 23px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease;
  font-weight: 600;
}

.InputText-prefix--show {
  opacity: 1;
  visibility: visible;
  transition: all 200ms ease;
}

.InputText-has-prefix {
  padding-left: 28px;
}
