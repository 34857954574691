/* text utilities */

/* weight */
.u-textBold {
  font-weight: 700;
}

.u-textSemiBold {
  font-weight: 600;
}

.u-textSemiSemiBold {
  font-weight: 500;
}

.u-textMedium {
  font-weight: 300;
}

.u-textThin {
  font-weight: 100;
}

.u-textLight {
  font-weight: 100;
}

.u-textUpper {
  text-transform: uppercase;
}

.u-textStartCase {
  text-transform: capitalize;
}

.u-textCapitalizeFirstLetter {
  text-transform: lowercase;
}

.u-textCapitalizeFirstLetter::first-letter {
  text-transform: uppercase !important;
}

.u-textDisabled {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-light !important;
  }
}

.u-textNoTransform {
  text-transform: none !important;
}

.u-textItalic {
  font-style: italic;
}

.u-textWideCurning {
  letter-spacing: 2px;
}

/* text colors */

.u-textPrimary {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-primary !important;
  }
}

.u-textSecondary {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-secondary !important;
  }
}

.u-textOwners {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-owners !important;
  }
}

.u-textowner {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-owners !important;
  }
}

.u-textrenter {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-primary !important;
  }
}

.u-textOwnersSecondary {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $sun !important;
  }
}

.u-textCaptains {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-captains !important;
  }
}

.u-textcaptain {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-captains !important;
  }
}

.u-textLightBlue {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-blue-light !important;
  }
}

.u-textStarRating {
  color: $colors-star-rating !important;
  font-size: 14px !important;
}

.u-textStarRatingOut {
  color: $colors-gray-lighter !important;
  font-size: 14px !important;
}

/* text color when primary bg */
.u-textPrimaryLight {
  color: $colors-primary-light;
}

.u-textDarkerGray {
  color: $dark-gray !important;
}

.u-textGrayDark {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-dark !important;
  }
}

.u-textBlack {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $bs-black !important;
  }
}

.u-textSecondaryLight {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-secondary-light !important;
  }
}

.u-textGrayMedium {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-medium !important;
  }
}

.u-textGrayLight {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-light !important;
  }
}

.u-textGrayLighter {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-lighter !important;
  }
}

.u-textExtraGrayLight {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-gray-extra-lighter !important;
  }
}

.u-textWhite {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
  }
}

.u-textYellow {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $sun !important;
  }
}

.u-textDanger {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-danger !important;
  }
}

.u-textSuccess {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-success !important;
  }
}

.u-textPositive {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $colors-positive !important;
  }
}

.u-textFacebook {
  color: $colors-facebook;
}

.u-textAdminRed {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $admin-header-secondary-color !important;
  }
}

.u-textUnderline {
  text-decoration: underline !important;
  &:hover {
    text-decoration: underline;
  }
}

/* line height */
.u-lh1_2 {
  line-height: 1.2;
}

.u-lh1_6 {
  line-height: 1.6;
  @media #{$media-md-max} {
    line-height: 1.3;
  }
}

.u-lh1_4 {
  line-height: 60px;
  @media #{$media-md-max} {
    line-height: 42px;
  }
}

.u-lh2 {
  line-height: 2;
}

.u-lh2_5 {
  line-height: 2.5;
}

.u-lh24px {
  line-height: 24px !important;
}

.u-lh34px {
  line-height: 34px;
}
.u-textpheader {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #17233c;
}
.u-text-generic {
  font-family: 'AvenirNext';
  font-style: normal;
  font-size: 16px;
  line-height: 44px;
  color: #17233c;

  @media #{$media-sm-max} {
    font-size: 0.875em;
    line-height: 20px;
  }
}

@media #{$media-md-viewport} {
  .u-md-textSemiBold {
    font-weight: 600;
  }
}

/* responsive alignment */
@media #{$media-sm-viewport} {
  .u-sm-textLeft {
    text-align: left !important;
  }
}

@media #{$media-sm-viewport} {
  .u-sm-textBold {
    font-weight: bold !important;
  }
}

@media #{$media-md-viewport} {
  .u-md-textLeft {
    text-align: left !important;
  }
}

@media #{$media-lg-viewport} {
  .u-lg-textLeft {
    text-align: left !important;
  }
}

@media #{$media-sm-viewport} {
  .u-sm-textRight {
    text-align: right !important;
  }
  .u-sm-textSemiBold {
    font-weight: 600;
  }
}

@media #{$media-md-viewport} {
  .u-md-textRight {
    text-align: right !important;
  }
}

@media #{$media-lg-viewport} {
  .u-lg-textRight {
    text-align: right !important;
  }
}

@media #{$media-sm-viewport} {
  .u-sm-textCenter {
    text-align: center !important;
  }
}

@media #{$media-md-viewport} {
  .u-md-textCenter {
    text-align: center !important;
  }
}

@media #{$media-lg-viewport} {
  .u-lg-textCenter {
    text-align: center !important;
  }
}

@media #{$ipad-pro-portrait-breakpoint} {
  .u-ip-textLeft {
    text-align: left !important;
  }
}

.u-subText {
  margin-bottom: 38px;
}

.u-textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 19ch;
}
