$size: 40px;
$maxSize: 52px;
$lineHeight: 3px;

.ProgressBar {
  position: relative;
}

@media #{$media-sm-max} {
  .ProgressBar {
    display: none;
  }
}

.progress {
  margin-bottom: $maxSize;
  &:nth-of-type(2) {
    width: 66%;
  }
  &:last-of-type {
    width: 40%;
  }
}

.boxes {
  display: flex;
  height: $maxSize;
  justify-content: space-between;
  align-items: center;
  top: 15px;
  position: relative;
  width: 100%;

  .box {
    height: $lineHeight;
    align-self: middle;
    position: relative;
    width: auto;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    // rail colour - future
    background: rgba(14, 120, 115, 0.14);

    &.past {
      // background: #0e7873;
    }
    &:last-of-type {
      flex: 0;
    }

    .inner {
      white-space: nowrap;
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      position: relative;
      align-self: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      border-radius: 50%;
      &:before {
        width: 6px;
        height: 6px;
        background: #0e7873;
        content: ' ';
        border-radius: 50%;
        display: flex;
        align-items: center;
        display: none;
      }
    }

    .legend {
      position: absolute;
      top: -30px;
      color: #0e7873;
      font-weight: 500;
    }

    .inner-first {
      align-items: flex-start;
    }

    .inner-last {
      align-items: flex-end;
    }

    &.current {
      .inner {
        &:before {
          width: 14px;
          height: 14px;
          background: #0e7873;
          content: ' ';
          display: flex;
          align-items: center;
          border-radius: 50%;
        }

        &:after {
          left: 5px;
          top: 5px;
          content: ' ';
          height: 4px;
          width: 4px;
          position: absolute;
          pointer-events: none;
          background: #ffffff;
          border-radius: 50%;
        }
      }

      .legend {
        font-weight: 800;
      }
    }

    &.past {
      .inner {
        &:before {
          width: 14px;
          height: 14px;
          background: #0e7873;
          content: ' ';
          display: flex;
          align-items: center;
          border-radius: 50%;
        }

        &:after {
          content: '\F050';
          pointer-events: none;
          color: #ffffff;
          font-family: 'BoatsetterV4';
          font-weight: bold;
          font-size: 0.65em;
          text-align: center;
          width: 14px;
          height: 14px;
          left: 0px;
          top: 0px;
          position: absolute;
          border-radius: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .boxes {
    width: 100%;
  }
  .boxes .box .legend {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1130px) {
  .boxes .box .legend {
    font-size: 0.75em;
    width: 100px;
    top: -40px;
  }

  .boxes .box .inner {
    white-space: inherit;
  }

  .boxes .box:first-child p {
    text-align: left;
  }

  .boxes .box:last-child p {
    text-align: right;
  }
}

@media only screen and (max-width: 768px) {
  .boxes {
    width: 80%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 768px) {
  .ProgressBar {
    display: none;
  }
}

.progress-bar-container {
  height: $lineHeight;
  background: rgba(14, 120, 115, 0.14);
  position: absolute;
  width: 100%;
  top: 39px;
}

.progress-bar {
  height: $lineHeight;
  background-color: #0e7873;
  width: 0%;
  transition: width 0.3s ease;
  margin: 0em 0em;
}

.reader {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  left: -10000px;
}
