/** @define Textarea; use strict */

.Textarea {
  @include controlSizeDefault;
  -webkit-appearance: none;
  box-shadow: none;
  outline: none;
  color: $colors-gray-dark;
  resize: vertical;
  border: solid 1px $colors-border;
  border-radius: $border-radius;
  padding-top: 28px;
  padding-left: 18px;
}

#MessageOwnerWidget {
  .Textarea {
    padding-top: 12px;
    &.is-invalid {
      color: $colors-danger;
    }
  }
}

.Textarea--withIcon {
  background-repeat: no-repeat;
  background-position: 16px 16px;
  padding-top: 13px;
  &:not(:placeholder-shown) {
    background-image: none !important;
  }
  &::placeholder {
    color: #979797 !important;
    font-weight: 600;
  }
  &.is-invalid {
    &::placeholder {
      color: #979797 !important;
      font-weight: 600;
    }
  }
}

.Textarea--message {
  min-height: 200px;
  @media #{$media-sm-max} {
    min-height: 200px;
  }
}

.Textarea.is-invalid {
  border-color: $colors-danger;
  &::placeholder {
    color: $sunset;
  }
}

.Textarea--plain {
  border: none;
}

.Textarea--dark {
  color: white;
  background: rgba(0, 0, 0, 0.16);
  border: solid 0px rgba(0, 0, 0, 0.16);
  &:focus {
    border: solid 0px $colors-border-dark;
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.36);
  }
  &:placeholder-shown {
    @media #{$media-sm-max} {
      height: 320.1px;
    }
  }
}

.Textarea--fat {
  padding: 1.8em;
}

textarea.Textarea:-webkit-autofill,
textarea.Textarea:-webkit-autofill:hover,
textarea.Textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  transition: background-color 5000s ease-in-out 0s;
}

textarea.Textarea--dark:-webkit-autofill,
textarea.Textarea--dark:-webkit-autofill:hover,
textarea.Textarea--dark:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.Textarea--owners {
  transition: all 200ms;
  &:focus {
    border: solid 1px #0e7873;
  }
}

.Textarea-label {
  transform: translate(18px, 15px) scale(1);
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: $rock;
  transform-origin: top left;
}

.Textarea-label--isFocused {
  transform: translate(19px, 5px) scale(0.75);
  color: $mid-rock;
}

.Textarea-placeholder {
  transform: translate(18px, 30px) scale(1);
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: $mid-rock;
  opacity: 0.4;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease;
}

.Textarea-placeholder--show {
  opacity: 0.4;
  visibility: visible;
  transition: all 200ms ease;
}
