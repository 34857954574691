@media only screen and (min-width: 1140px) and (max-width: 1500px) {
  .secondValueItemSeparator {
    display: none;
  }
  .secondValueItem {
    display: block;
  }
  .secondValueWrapper {
    line-height: 1.4em;
  }
}

@media (max-width: 375px) {
  .secondValueItemSeparator {
    display: none;
  }
  .secondValueItem {
    display: block;
  }
  .secondValueWrapper {
    line-height: 1.4em;
  }
}

// modals actions styles
@media (max-width: 540px) {
  .u-smMd-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
}
