/** @define Dropdown; use strict */

.Dropdown-wrapper {
  position: relative;
}

.Dropdown-trigger {
  cursor: pointer;
}

.Dropdown {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  width: 1px;
  background: #fff;
  position: absolute;
  opacity: 0;
  display: none;
  background-color: #fff;
  max-width: $viewport-max-sm;
  min-width: 16em;
  border-radius: $border-radius;
  top: 100%;
  right: 0;
  margin-top: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
}

.dots {
  font-weight: bold;
  color: white;
  font-size: 20px;
  line-height: 52px;
  text-align: center;
  height: 60px;
  letter-spacing: 2px;
  cursor: pointer;
}

.DropdownExtra {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  flex-wrap: wrap;
  position: absolute;
  opacity: 0;
  padding: 2em;
  display: none;
  margin-top: 12px !important;
  right: -464px;
  min-width: 700px;
  max-height: 500px;
  border-radius: 6px !important;
  top: 100%;
  gap: 0 30px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25) !important;

  &.blwNav {
    background: white;
    z-index: 222222;
    top: 200%;
    &:before {
      border-bottom-color: white;
    }
  }

  &.notAdmin {
    right: -284px;
    &::before {
      right: 45.4%;
    }
  }

  &.isAdmin {
    right: -340px;
    &::before {
      right: 54.1%;
    }
  }

  &-link {
    &.notLoggedIn {
      font-size: 14px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    max-height: 435px;
    flex-direction: column;
    li {
      min-width: 180px;
      margin-right: 45px;

      &.experiences.last {
        padding-bottom: 90px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  &:before {
    right: 66.7%;
    top: -10px;
    border: 10px solid transparent;
    border-top: none;
    display: inline-block;
    position: absolute;
    bottom: auto;
    content: '';
    border-bottom-color: #1c3566;
  }

  &.is-active {
    clip: auto;
    //height: auto;
    overflow: visible;
    z-index: $z-index-dropdown;
    opacity: 1;

    display: flex;
  }
}

.Dropdown-divider {
  width: 100%;
  padding: 0 0 1em 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 0 1em 0;
}

.Dropdown--darkBlue {
  background: $bs-darkBlue;
  margin-top: 31px;
  border-radius: 0;
  box-shadow: none;
}

.DropdownExtra--darkBlue {
  background: #1c3566;
  margin-top: 31px;
  border-radius: 0;
  box-shadow: none;
}

.Dropdown--darkGreen {
  // background: $bs-darkGreen;
  margin-top: 31px;
  border-radius: 0;
  box-shadow: none;
}

.Dropdown--aquamarine {
  background: $oceanic-darker;
  margin-top: 31px;
  border-radius: 0;
  box-shadow: none;
}

.Dropdown--darkAquamarine {
  // background: $captain;
  margin-top: 31px;
  border-radius: 0;
  box-shadow: none;
}

.Dropdown--light {
  background: #fff;
  margin-top: 31px;
  border-radius: 0;
}

.Dropdown-menu--inversePlacement {
  left: 0 !important;
}

.Dropdown-menu--inversePlacement:before {
  right: 248px !important;
}

.Dropdown-menu--inversePlacement:after {
  right: 249px !important;
}

.Dropdown--notifications,
.Dropdown--searchOptions {
  right: -0.5em;
}

.Dropdown--searchOptions {
  left: 0;
  right: auto;
  top: 115%;
  min-width: 24em;
}

.Dropdown-notificationsScrollbox {
  max-height: 37em;
  overflow-y: auto;
}

@media #{$media-sm-viewport} {
  .Dropdown-notificationsScrollbox {
    max-height: 28em;
  }
}

.Dropdown--narrow {
  min-width: auto;
}

.Dropdown--wide {
  min-width: 18em;
}

.Dropdown--small {
  min-width: 10em;
}

.Dropdown--search {
  min-width: 100%;
  right: 0;
  margin-top: 0;
  box-shadow: none;
  border-radius: 0;
  padding: 0.5em;
  background-color: $colors-primary;
}

.Dropdown:before,
.Dropdown:after {
  display: inline-block;
  position: absolute;
  bottom: auto;
  content: '';
}

.Dropdown:before {
  right: 8px;
  top: -10px;
  border: 10px solid transparent;
  border-top: none;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px -1px 1px rgba(130, 130, 130, 0.3));
}

.Dropdown--darkBlue:before {
  border-bottom-color: $bs-darkBlue;
}

.Dropdown--darkGreen:before {
  border-bottom-color: #fff;
}

.Dropdown--aquamarine:before {
  border-bottom-color: $oceanic-darker;
}

.Dropdown--darkAquamarine:before {
  border-bottom-color: #fff;
}

.Dropdown:after {
  right: 8px;
  top: -9px;
  border: 9px solid transparent;
  border-top: none;
  border-bottom-color: #fff;
}

.Dropdown--darkBlue:after {
  border-bottom-color: $bs-darkBlue;
}

.Dropdown--darkGreen:after {
  border-bottom-color: #fff;
}

.Dropdown--aquamarine:after {
  border-bottom-color: $oceanic-darker;
}

.Dropdown--darkAquamarine:after {
  border-bottom-color: #fff;
}

.Dropdown--searchOptions:before {
  left: 22px;
  right: auto;
}

.Dropdown--searchOptions:after {
  left: 23px;
  right: auto;
}

.Dropdown--search:after,
.Dropdown--search:before {
  border-width: 0;
}

.Dropdown.is-active {
  clip: auto;
  height: auto;
  overflow: visible;
  width: auto;
  z-index: 1000;
  opacity: 1;
  display: block;
}

.js-date-picker {
  .Dropdown.is-active {
    z-index: 10;
  }
}

.Dropdown-menu {
  padding: 0;
  overflow-x: scroll;
  max-height: 560px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Dropdown-menu::-webkit-scrollbar {
  display: none;
}

.Dropdown-menu-divider {
  width: calc(100% - 3em);
  padding: 0 0 1em 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 auto 1em auto;
}

.bottom-border {
  border-bottom: 1px solid #ededed !important;
}

.Dropdown-section {
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  width: calc(100% - 3em);
  margin: 0 auto 1em auto;
  padding: 0 0 1em 0;
  &:last-child,
  &--noBorder {
    border-bottom: none;
    margin: 0 auto;
  }
}

.Dropdown--darkBlue .Dropdown-section--topSection {
  background-color: lighten($bs-darkBlue, 3%);
}

.Dropdown--darkGreen .Dropdown-section--topSection {
  // background-color: lighten($bs-darkGreen, 3%);
}

.Dropdown--darkAquamarine .Dropdown-section--topSection {
  background-color: lighten($captain, 3%);
}

.Dropdown-section--topSection {
  // border-bottom: none;
  // border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  width: 100%;
  padding: 0.5em 1.5em;
  color: #102a5e;

  li {
    border-bottom: solid 1px #eaebeb;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 46px;
    letter-spacing: 0.2px;
    margin-top: -24px;
    display: block;
    color: #0a4195;
  }
}

.Dropdown-item {
  display: block;
  padding: 0.75em 1.5em;

  &:hover,
  &:focus,
  &:active {
    background-color: $colors-border-light;
    color: $colors-gray-dark;
  }
}

.u-textAqua {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #00677d !important;
  }
}

.u-textGreen {
  color: #0e7873 !important;
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #0e7873 !important;
  }
}

.u-textDarkBlue {
  color: #0a4195 !important;
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #0a4195 !important;
  }
}

.Dropdown--darkBlue .Dropdown-item,
.Dropdown--darkGreen .Dropdown-item,
.Dropdown--aquamarine .Dropdown-item,
.Dropdown--darkAquamarine .Dropdown-item {
  display: block;
  padding: 0.5em 0;
  &,
  &:link,
  &:visited {
    // color: #fff;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    // color: #fff;
  }
}

.Dropdown-item--small {
  padding: 0 0.5em 0 0;
  &,
  &:link,
  &:visited {
    color: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #fff;
  }
}

@media #{$media-sm-viewport} {
  .Dropdown--notifications {
    right: -3.75em;
  }

  .Dropdown.Dropdown--small:before {
    right: 19px;
  }

  .Dropdown.Dropdown--small:after {
    right: 20px;
  }
}

@media #{$media-sm-max} {
  .Dropdown--mobile {
    top: 48px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: $viewport-max-sm) {
  .Dropdown--mobile {
    transform: translateX(-50%);
    position: absolute;
    top: 30px;
    right: -27%;
    max-width: 280px;
  }

  .BLWHeader {
    .Dropdown--mobile {
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(50%);
      position: absolute;
      top: 30px;
      right: 50%;
      max-width: 250px;
    }
    .Dropdown.Dropdown--mobile:before {
      right: 45%;
    }

    .Dropdown.Dropdown--mobile:after {
      right: 45%;
    }
  }

  .Dropdown.Dropdown--mobile:before {
    right: 36%;
  }

  .Dropdown.Dropdown--mobile:after {
    right: 36%;
  }
  .Dropdown-mobileMenu--mobile {
    position: absolute;
    margin-top: 13px !important;
    border-radius: 6px;
  }
  .Dropdown-mobileMenu--mobile ul li:first-child a {
    margin-top: 1em;
  }
  .Dropdown-mobileMenu--mobile ul li:last-child a {
    margin-bottom: 1em;
  }
  .Dropdown-mobileMenu--mobile ul li a {
    font-size: 0.9375em;
    color: #17233c !important;
  }

  .Dropdown.Dropdown-mobileMenu--mobile:after,
  .Dropdown.Dropdown-mobileMenu--mobile:before {
    right: 50%;
    transform: translateX(50%);
  }
}

.Dropdown-title {
  line-height: normal;
  align-self: center;
  margin-bottom: 0.2em;
}
