$size: 40px;
$maxSize: 52px;
$lineHeight: 4px;

.ProgressBarMobile {
  position: relative;
  margin: 0em 0.5em;
}

.boxesMobile {
  .boxMobile:first-child {
    padding-top: 0;
  }
  .boxMobile:last-child {
    padding-bottom: 0;
  }
  .boxMobile {
    padding-top: 1em;
    padding-bottom: 1em;
    .inner {
      position: relative;
      &:before {
        white-space: nowrap;
        box-sizing: border-box;
        width: 6px;
        height: 6px;
        content: ' ';
        position: absolute;
        background: #0e7873;
        border-radius: 50%;
        left: 0px;
        top: 8px;
      }
      img {
        position: absolute;
        left: 0px;
        top: 8px;
      }
    }

    .legend {
      color: #0e7873;
      padding-left: 2em;
    }

    &.current {
      .inner {
        &:before {
          width: 14px;
          height: 14px;
          background: #0e7873;
          content: ' ';
          position: absolute;
          top: 4px;
          left: -4px;
        }

        &:after {
          top: 8px;
          left: 0px;
          content: ' ';
          height: 4px;
          width: 4px;
          position: absolute;
          pointer-events: none;
          border-radius: 50%;
        }
      }
      img {
        position: absolute;
        left: -4px;
        top: 4px;
      }
      .legend {
        position: relative;
        font-weight: bold;
      }
    }
    &.past {
      .inner {
        &:before {
          width: 14px;
          height: 14px;
          background: #fff;
          content: ' ';
          position: absolute;
          top: 4px;
          left: -4px;
        }

        &:after {
          top: 1px;
          left: -6px;
          border: solid transparent;
          content: ' ';
          position: absolute;
          pointer-events: none;
          border-radius: 50%;
          font-weight: bold;
          font-size: 0.65em;
        }
      }
      img {
        position: absolute;
        left: -4px;
        top: 4px;
      }
      .legend {
        position: relative;
      }
    }
  }
}

.progress-bar-mobile {
  width: 4px;
  background-color: #0e7873;
  height: 0%;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: width 0.3s ease;
  padding: 0em 0em;
}

.progress-bar-container-mobile {
  height: 92%;
  background: rgba(14, 120, 115, 0.14);
  position: absolute;
  width: 2px;
  top: 6px;
  left: 2px;
}

.progress-bar-container-mobile-footerbar {
  background: #d2e1e0;
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  display: none;
  @media screen and (max-width: 640px) {
    display: block;
  }
  .progress-bar {
    height: 5px;
  }
}

.page-blw-details {
  .progress-bar-container-mobile-footerbar {
    @media screen and (max-width: 640px) {
      display: none;
    }
  }
}
