// .customTipEarningsTooltip {
//   position: relative;
//   top: 6px;
//   margin-left: 6px;
//   font-size: 11px;
// }

.header-menu-open {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0a252f;
  width: 100%;
  display: flex;

  a {
    display: flex;
    align-items: center;
    text-align: center;

    svg {
      width: 138px;
      height: 32px;
    }
  }
}

.header-menu-padding {
  padding-left: 4em;
}

.close-icon {
  background: #f5f7f9;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  i {
    margin-top: 2px;
  }
}

.page-blw-home {
  @media #{$media-lg-min} {
    .PageWrapper-content {
      min-height: 100vh;
    }

    .NavTop-push {
      min-height: 90vh;
      display: flex;
      align-items: center;
    }
  }
}

.page-blw-add-more-boats {
  .PageWrapper-content {
    display: flex;
    align-items: center;
  }
}

.page-blw-location {
  padding-bottom: 1em !important;

  .Page-rightContentWrapper {
    padding-top: 50px;
    @media #{$media-sm-max} {
      padding-top: 32px;
    }
  }

  .Header-estimatedEarnings--mobile {
    background: transparent;
  }
  .PageWrapper-content {
    width: 100%;
    position: sticky;
    height: 100%;
    padding-top: 0;
    h1 {
      letter-spacing: -0.02em;
    }

    h3 {
      font-size: 24px;
    }
    @media #{$media-md-max} {
      h3 {
        font-size: 20px;
        margin-top: 30px;
      }
    }
    @media #{$media-lg-min} {
      .Panel {
        padding-left: 67px;
        padding-right: 72px;
      }
      .PageWrapper-title {
        top: 30%;
        position: fixed;
        transition: top 0.3s ease;
        max-width: 520px;
      }
      .PageWrapper-title--opened {
        top: 20%;
      }
    }
  }

  @media #{$media-md-max} {
    .PageWrapper-content {
      position: relative;
    }
  }
  @media #{$media-lg-min} {
    .u-lg-scrollY {
      overflow-y: scroll;
    }
  }
}

.u-overflowHidden {
  overflow: hidden;
}

.u-overflow-unset {
  overflow: unset !important;
}

.blw-p {
  line-height: 24px;
  font-size: 16px;
  span {
    display: block;
    width: 100%;
  }
  @media #{$media-sm-max} {
    font-size: 15px;
    line-height: 1.7em;
  }
}

.page-blw-commercial {
  .PageWrapper-title {
    padding: 0em 1em;
    @media #{$media-lg-min} {
      max-width: 570px;
      margin-bottom: 0;
    }
  }
}

.page-blw-international {
  .PageWrapper-title {
    padding: 0em 1em;
    @media #{$media-lg-min} {
      max-width: 580px;
    }
  }
}

.page-blw-details {
  .PageWrapper-content {
    justify-content: center;
  }
}

.page-blw-commercial,
.page-blw-international,
.page-blw-details,
.page-blw-location-pin,
.page-blw-description {
  .PageWrapper-content {
    display: flex;
    align-items: center;
  }
}

.page-blw-location-pin {
  .PageWrapper-content {
    justify-content: center;
  }
}

.page-blw-availability {
  padding-bottom: 0;
  .PageWrapper-title {
    @media #{$media-lg-min} {
      max-width: 520px;
      position: fixed;
      top: 25%;
    }
  }
}

.page-blw-insurance-type,
.page-blw-availability {
  .PageWrapper-content {
    padding-top: 0;
  }

  .PageWrapper-title {
    max-width: 520px;
  }
}

.page-blw-insurance-info {
  .PageWrapper-title {
    max-width: 520px;
  }
}
.page-blw-features {
  .PageWrapper-title {
    padding: 0 1.6em;
    @media #{$media-lg-min} {
      max-width: 500px;
      position: absolute;
      top: 35%;
    }
  }
}

.page-blw-rules {
  .PageWrapper-content {
    padding-top: 0;
  }
  .PageWrapper-contentContainer {
    @media #{$media-lg-min} {
      min-height: 97vh;
    }
  }
  .PageWrapper-title {
    @media #{$media-lg-min} {
      max-width: 520px !important;
    }
  }
}

.page-blw-advance-notice {
  .PageWrapper-content {
    padding-top: 0;
  }
  .PageWrapper-contentContainer {
    @media #{$media-lg-min} {
      min-height: 97vh;
    }
  }
  .PageWrapper-title {
    @media #{$media-lg-min} {
      max-width: 520px !important;
    }
  }
}

.page-blw-title,
.page-blw-photos {
  .PageWrapper-contentContainer {
    min-height: 82vh;
  }
}

.page-blw-photos {
  .PageWrapper-contentContainer {
    margin-bottom: 9em;
  }
}

.page-blw-cancellation-policy {
  @media #{$media-lg-min} {
    .PageWrapper-content {
      width: 100%;
      position: sticky;
      height: 100%;
      padding-top: 0;
      @media #{$media-lg-min} {
        // padding-bottom: 4em;
      }
    }

    .Panel.Panel-half {
      padding-bottom: 85px;
      padding-top: 66px;
      width: 100vh;
      position: absolute;
      border-radius: 0;
    }

    .PageWrapper-title {
      top: 38%;
      position: fixed;
      max-width: 520px;
    }
  }
  @media #{$media-md-max} {
    .PageWrapper-title {
      padding: 0 1.6em;
    }
  }
}

.page-blw-rental-options,
.page-blw-safety-standards {
  .PageWrapper-content {
    @media #{$media-lg-min} {
      padding-top: 0px;
      min-height: 87vh;
    }
  }

  @media #{$media-lg-min} {
    .PageWrapper-contentContainer {
      min-height: 87vh;
    }
  }

  .PageWrapper-title {
    @media #{$media-lg-min} {
      padding-left: 4em;
    }
    @media #{$media-md-max} {
      padding: 0 1.6em;
    }
  }
}

.page-blw-pricing {
  .PageWrapper-title {
    padding: 0 1.6em;
    @media #{$media-lg-min} {
      max-width: 560px;
      position: absolute;
      top: 20%;
    }
  }
}

.page-blw-multi-booking {
  .PageWrapper-content {
    padding-top: 0;
  }
  .PageWrapper-title {
    padding: 0 1.6em;
    @media #{$media-lg-min} {
      max-width: 520px;
      position: fixed;
      top: 25%;
    }
  }
}

.page-blw-photos {
  .PageWrapper-title {
    padding: 0 1.6em;
  }
}

.page-blw-safety-standards-agreement {
  .PageWrapper-title {
    padding: 0 1.6em;
    @media #{$media-lg-min} {
      max-width: 560px;
      position: absolute;
      top: 35%;
    }
  }
}

@media #{$media-lg-min} {
  .PageWrapper-contentContainer {
    min-height: 97vh;
  }
}

.page-blw-title {
  .blw-boat-img-wrap {
    overflow: hidden;
    min-width: 113px;
    max-width: 113px;
    height: 76px;
    background: #aaa;

    img {
      width: 100%;
      height: auto;
    }
  }
  @media #{$media-sm-max} {
    .blw-boat-img-wrap {
      // min-width: 81px!important;
      // height: 52px!important;
      position: relative;
      // max-width: 81px;
    }
  }

  .PageWrapper-title {
    padding: 0 1.6em;
    @media #{$media-lg-min} {
      max-width: 560px;
      position: absolute;
    }
  }
}

.page-blw-description {
  li {
    list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='-2 -2 4 4'><circle r='0.7' /></svg>");

    span {
      margin-left: 10px;
      font-size: 16px;
      line-height: 32px;
      color: #17233c;
    }
  }
}

.blw {
  @media #{$media-sm-max} {
    form {
      .Panel {
      }
    }
  }
  .checkboxoption {
    width: 30%;

    @media #{$media-sm-max} {
      width: 48%;
    }
  }
  .checkboxeswrapper {
    @media #{$media-sm-max} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.page-manage {
  .manage-boat-nav {
    a {
      font-family: 'AvenirNext';
      font-style: normal;
      font-size: 14px;
      line-height: 22px;
      color: #17233c;

      &.greenarrow {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #0e7873;
      }
    }
    .Label--owners.Label-midSize.u-fsTiny {
      font-size: 10px;
    }
  }
  .hidden {
    display: none;
  }

  @media only screen and (max-width: 640px) and (min-width: 320px) {
    .Container,
    .Container--xxl {
      padding: 0.2em;
    }
    .Grid--withGutter {
      margin-left: 0;
      margin-right: 0;
    }

    #js-footer {
      .Container,
      .Container--xxl {
        padding: 0 1.656em;
      }
    }
  }
  @media only screen and (min-width: 745px) and (max-width: 820px) {
    .CancellationPolicyWrapper {
      .u-ip-size1of3,
      .u-lg-min-size6of12 {
        width: 100% !important;
      }
      .u-sm-show {
        display: block !important;
      }
      .u-sm-hide {
        display: none !important;
      }
      .TextWrapper {
        display: block !important;
        margin-bottom: 0.5em;
      }
      .Label--owners {
        margin-bottom: 1em;
      }
      h4 {
        margin-bottom: 0;
      }
    }
  }

  .BookingSelect__control {
    border: 1px solid #8e9697 !important;
    border-radius: 6px;
    .BookingSelect__single-value {
      margin-top: 0 !important;
    }
    .BookingSelect__value-container {
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 960px) {
      height: 50px;
      padding: 0 0.9em;
    }
    .BookingSelect__placeholder {
      color: #17233c !important;
    }
    .Select-figure {
      i {
        color: $sea-weed !important;
      }
    }
  }
}

.CancellationPolicyWrapper {
  justify-content: center;
}

@media #{$media-size-lg-desktop-viewport} {
  .CaptainPriceWrapper {
    .u-size1of2 {
      width: 100% !important;
    }
    .u-size1of2:first-child {
      margin-bottom: 1em;
    }
  }
  .CancellationPolicyWrapper {
    .u-ip-size1of3,
    .u-lg-min-size6of12 {
      width: 100% !important;
    }
    .u-sm-show {
      display: block !important;
    }
    .u-sm-hide {
      display: none !important;
    }
    .TextWrapper {
      display: block !important;
      margin-bottom: 0.5em;
    }
    .Label--owners {
      margin-bottom: 1em;
    }
    h4 {
      margin-bottom: 0;
    }
    .CancellationPolicyTitle {
      display: flex;
      align-items: center;
      h4 {
        flex: 1 1;
      }
    }
  }
  .page-blw-rules {
    .u-lg-min-size1of2 {
      width: 50% !important;
    }
  }

  .page-blw-specifications {
    .u-lg-min-size1of2 {
      width: 50% !important;
    }
  }
}

@media only screen and (min-width: 745px) and (max-width: 960px) {
  .page-blw-rules {
    .u-ip-size1of3 {
      width: calc(100% * 1 / 3) !important;
    }
  }

  .page-blw-specifications {
    .u-ip-size1of3 {
      width: calc(100% * 1 / 3) !important;
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 745px) {
  .CaptainPriceWrapper {
    .u-size1of2 {
      width: 100% !important;
    }
    .u-size1of2:first-child {
      margin-bottom: 1em;
    }
  }
  .CancellationPolicyWrapper {
    .u-ip-size1of3,
    .u-lg-min-size6of12 {
      width: 100% !important;
    }
    .u-sm-show {
      display: block !important;
    }
    .u-sm-hide {
      display: none !important;
    }
    .TextWrapper {
      display: block !important;
      margin-bottom: 0.5em;
    }
    .Label--owners {
      margin-bottom: 1em;
    }
    h4 {
      margin-bottom: 0;
    }

    .CancellationPolicyTitle {
      display: flex;
      align-items: center;
      h4 {
        flex: 1 1;
      }
    }
  }
}

@media only screen and (min-width: 960px) and (max-width: 1300px) {
  .page-blw-safety-standards-agreement {
    br {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 720px) {
  .page-blw-safety-standards-agreement {
    br {
      display: none !important;
    }
  }
}

@media only screen and (min-width: 1241px) and (max-width: 1394px) {
  .MarinaInfoWrapper {
    .SlipNumberWrapper {
      border-right: 1px solid rgba(255, 255, 255, 0.32);
      margin-right: 1.3em;
      padding-right: 0.5em;
      width: 50% !important;
    }

    .SeparatorA {
      width: 5% !important;
    }
    .SeparatorB {
      display: none;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .MarinaInfoWrapper {
    .SlipNumberWrapper {
      border-bottom: 1px solid rgba(255, 255, 255, 0.32);
      margin-right: 0em;
      padding-right: 0em;
      width: 100% !important;
      padding-bottom: 0em;
      margin-bottom: 2em;
    }
    .DockWrapper {
      width: 100% !important;
    }
    .SeparatorB {
      display: none;
    }
    .MarinaNameWrapper {
      width: 50% !important;
    }
    .OtherInfoWrapper {
      width: 40% !important;
    }
    .MarinaNameWrapper {
      width: 50% !important;
    }
  }
}

@media #{$media-lg-min} {
  .page-blw-specifications {
    .PageWrapper-title {
      padding-bottom: 0;
    }
  }
}

.page-blw-specifications {
  padding-bottom: 0;
}

.page-blw-specifications,
.page-blw-insurance-info {
  .PageWrapper-title {
    padding: 0 1.6em;
    @media #{$media-lg-min} {
      max-width: 520px;
      position: absolute;
      top: 35%;
    }
  }

  .PageWrapper-content {
    padding-top: 0;
  }
  .PageWrapper-contentContainer {
    @media #{$media-lg-min} {
      min-height: 97vh;
    }
  }
  .PageWrapper-title {
    @media #{$media-lg-min} {
      max-width: 520px !important;
    }
  }
}

.page-blw-insurance-info {
  .PageWrapper-title {
    padding: 0 1.6em;
    @media #{$media-lg-min} {
      top: 18%;
      position: fixed;
      max-width: 520px;
    }
  }
}

.Page-rightContentWrapper {
  max-width: 560px;
  width: 100%;
}

.Page-rightContentWrapper--large {
  max-width: 620px;
  width: 100%;
}

.Page-rightContentWrapper--xlarge {
  max-width: 690px;
  width: 100%;
}

.Page-rightContentWrapper--xxlarge {
  max-width: 810px;
  width: 100%;
}

@media #{$media-sm-max} {
  .CancellationPolicyTitle {
    display: flex;
    align-items: center;
    h4 {
      flex: 1 1;
    }
  }
}

.page-blw-finished {
  .PageWrapper-content {
    justify-content: center;
  }
}

.page-blw-insurance,
.page-blw-finished,
.page-blw-help {
  .PageWrapper-content {
    display: flex;
    align-items: center;
  }
}

.page-blw-review {
  .PageWrapper-content {
    padding-top: 60px;
  }
  .NavTop-push {
    background: linear-gradient(149.73deg, #063735 -2.87%, #0e7873 59.26%, #80ac66 109.13%);
  }

  .PageWrapper-title {
    padding: 4em 0;
  }
}

@media #{$media-md-max} {
  .PageWrapper-title {
    h1 {
      margin-bottom: 24px;
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
}

@media #{$media-sm-max} {
  .PageWrapper-title {
    padding: 38px 24px !important;
    margin-bottom: 0;
    font-size: 24px;
  }
}

.ManageFormContent {
  max-width: 400px;
}

.sectionReviewTitle {
  position: -webkit-sticky;
  position: sticky;
}

.PanelDetails {
  @media #{$media-sm-max} {
    height: 70vh;

    @media (max-height: 846px) {
      height: 60vh;
    }
    @media (max-height: 812px) {
      height: 58vh;
    }
    @media (max-height: 736px) {
      height: 100%;
    }

    @media (max-height: 568px) {
      height: 100%;
    }
  }
}

.CategoryOpen {
  margin-bottom: 120px;
  @media #{$media-sm-max} {
    margin-bottom: 80px;
  }
}

.page-blw-start-times {
  @media #{$media-lg-min} {
    .PageWrapper-title {
      top: 30%;
      position: fixed;
      transition: top 0.3s ease;
      max-width: 520px;
    }
  }
}

.StartTimesWrapper {
  @media #{$media-lg-viewport} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  @media #{$media-md-viewport} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  @media #{$media-sm-viewport} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media #{$media-sm-viewport} {
  .BufferTimeLastItem > div {
    margin-bottom: 0em !important;
  }
  .BufferTimeLastItem > div > div {
    margin-bottom: 0.2em !important;
  }
}
