/* utilities to hide/show based on media query */

/* hide on small */

.u-ip-hide {
  @media #{$ipad-pro-portrait-breakpoint} {
    display: none;
  }
}

.u-sm-hide {
  @media only screen and (max-width: #{$viewport-max-sm}) {
    display: none !important;
  }
}

.u-lg-hide {
  @media #{$media-lg-min} {
    display: none;
  }
}

.u-lg-min-hide {
  @media #{$media-size-lg-viewport} {
    display: none !important;
  }
}

.u-md-hide {
  @media #{$media-md-max} {
    display: none;
  }
}


.u-sm-show {
  display: none;
  @media #{$media-sm-max} {
    display: block;
  }
}





