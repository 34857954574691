/** @define InputAddOn; use strict */

.InputAddOn {
  @include controlSizeDefault;
  @include inputText;
  border: solid 1px $colors-border;
}

.InputAddOn--prefix {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-right: 0;
  background: $colors-bg-body;
  &.is-invalid {
    border: solid 1px #bc350a;
    border-right: solid 0px #bc350a;
  }
}

.InputAddOn--prefixBig {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-right: 0;
  background: $colors-bg-body;
  padding: 1.54em 1em;
  @media #{$media-sm-max} {
    padding: 1.4em 1em;
    height: 64px;
  }
  &.is-invalid {
    border: solid 1px #bc350a;
    border-right: solid 0px #bc350a;
  }
}

.InputAddOn--prefixWhite {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-right: 0;
  background: #fff;
  padding-right: 0;
  &.is-invalid {
    border: solid 1px #bc350a;
    border-right: solid 0px #bc350a;
  }
}

.InputAddOn--suffix {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-left: 0;
  background: $colors-bg-body;
}

.InputAddOn--suffixWhite {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-left: 0;
  background: #fff;
}

.InputAddOn--oneLine {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.InputAddOn--big {
  padding: 1.5em 1em;
}

.InputWithAddon {
  border-radius: 6px;
  border: solid 1px #dbdfe5;
  transition: all 200ms;

  div {
    border-width: 0 !important;
  }
  .InputText {
    border-width: 0 !important;
    &:focus {
      border-color: transparent;
    }
  }

  &:focus-within {
    border: solid 1px #0e7873;
  }

  &.is-invalid {
    border: solid 1px $colors-danger;
  }
}

.blw {
  .InputWithAddon {
    .InputAddOn {
      height: 50px;
      background: #fff;
      color: #0e7873;
    }
    position: relative;
  }
}
